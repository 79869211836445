.order-summary-first {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    margin-bottom: 1rem;
}

.summary-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    color: #666;
}

.summary-item-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
    padding-top: 1rem;
    border-top: 2px solid #eee;
    font-weight: 600;
    font-size: 1.1rem;
    color: #333;
}

.coupon-discount {
    color: #28a745;
}

.coupon-section {
    margin: 1rem 0;
}

.coupon-card-form {
    display: flex;
    gap: 0.5rem;
}

.coupon-input {
    flex: 1;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.9rem;
    outline: none;
}

.coupon-button {
    background-color: #425A8B;
    color: white;
    border: none;
    padding: 0.2rem 0.4rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 100px;
}

.coupon-button:hover {
    background-color: #2d4575;
}

.coupon-error {
    color: #dc3545;
    font-size: 0.9rem;
    margin-top: 0.5rem;
}

.coupon-success {
    color: #28a745;
    font-size: 0.9rem;
    margin-top: 0.5rem;
}

.coupon-remove {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0px;
}

.coupon-remove button {
    background-color: #425A8B;
    color: white;
    border: none;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.coupon-remove button:hover {
    background-color: #2d4575;
}