
.category-dropdown {
  background-color: #f3f4ff;
  padding: 5px;
  border-bottom: 1px solid #ddd;
  display: flex;
  justify-content: center;
  width: 100%;
  
  position: relative;
  
}
.toggle-icons{
 
  display: none;
}
.main-categories {
  max-width: 1500px;
  display: flex;
  justify-content: space-around;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  position: relative;
}
.main-categories.open {
  display: block; 
}
.category-item {
  position: relative;
}

.category-item > a {
  font-weight: bold;
  text-decoration: none;
  color: #495e95;
  font-size: 14px;
  padding: 5px;
  display: block;
  white-space: nowrap;
}

.category-item > a:hover {
  color: orange;
}

.sub-categories {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  display: none;
  width: 100px;
  min-width: 100%;
  padding: 0;
  z-index: 1001;
}

.category-item:hover .sub-categories {
  display: block;
}

.sub-categories li {
  list-style: none;
  margin: 0;
  padding: 4px 0;
}

.sub-categories li a {
  text-decoration: none;
  color: #425A8B;
  padding: 8px 16px;
  display: block;
  font-size: 14px;
  transition: color 0.3s ease;
}

.sub-categories li a:hover {
  color: orange;
  background-color: #f5f5f5;
}
.category-item.active .sub-categories {
  display: none;
}
.hamburger-menu {
  display: none;
  cursor: pointer;
  background-color: #f3f4ff;
  padding: 15px;
  font-weight: bold;
  color: #495e95;
}
@media screen and (min-width: 769px) {
  .category-item:hover .sub-categories {
      display: block;
  }
  .category-item.active .sub-categories {
    display: none;
}
}
@media screen and (max-width: 1600px) {
  
    .category-dropdown {
      padding: 0; 
    }
  
    .main-categories {
      width: 100%;
    }
  
    .category-item > a {
      font-size: 12px; 
      padding: 10px 6px;
    }
  
    .sub-categories {
      width: 250px; 
    }
  
    .category-item:hover .sub-categories {
      display: block;
    }
  }
  



@media screen and (max-width: 1080px) {
  .hamburger-menu {
      display: block;
  }
  .toggle-icons{
    display: block;
    display: flex;
    justify-content: flex-end; 
    margin-top: 12px;
    padding-right: 10px;
  }
  .category-dropdown {
      padding: 0;
      display: block;
  }

  .main-categories {
 
      display: none;
      width: 100%;
      flex-direction: column;
      background-color: #f3f4ff;
  }

  .main-categories.open {
      display: block;
  }

  .category-item {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid #ddd;
      
  }

  .category-item > a {
      padding: 12px 16px;
      font-size: 14px;
  }

  .sub-categories {
      position: absolute;
      display: none;
      box-shadow: none;
      background-color: #eaebff;
      border-radius: 0;
      padding-left: 20px;
  }

  .sub-categories.show {
      display: block;
  }

  .category-item:hover .sub-categories {
      display: none;
  }

  .category-item.active .sub-categories {
      display: block;
  }
}


.menu-open {
  background-color: rgba(0, 0, 0, 0.1);
}
