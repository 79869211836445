.share-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 2rem;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .share-modal-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .share-modal-content h2 {
    color: #333;
    margin: 0;
  }
  
  .share-url-container {
    display: flex;
    gap: 0.5rem;
  }
  
  .share-url-input {
    flex: 1;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.9rem;
  }
  
  .copy-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    white-space: nowrap;
  }
  
  .copy-button:hover {
    background-color: #0056b3;
  }
  
  .share-code-info {
    background-color: #f8f9fa;
    padding: 1rem;
    border-radius: 4px;
    font-size: 0.9rem;
  }
  
  .share-note {
    color: #666;
    font-size: 0.9rem;
    font-style: italic;
  }
  
  .share-modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
  }
  
  .close-share-modal {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .close-share-modal:hover {
    background-color: #5a6268;
  } 