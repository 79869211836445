.login-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  
}
.login-baslik p {
  font-size: 14px;
  color: #6c757d;
  margin-bottom: 20px;
}

.form-login label {
  font-size: 14px;
  color: #333;
  margin-bottom: 5px;
  display: block;
  float: left;
  margin-bottom: 5px;
}
.form-login input {
  line-height: 25px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box; 
  margin: 5px 0;
  margin-bottom: 15px;
  width: 100%;
}
.form-login input[type="email"],
.form-login input[type="password"],
.form-login input[type="text"] {
  width: 100%;
}

.password-input-container {
  position: relative;
  width: 100%;
}

.password-toggle-icon {
  position: absolute;
  right: 10px;
  top: 74%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #6c757d;
  z-index: 10;
  font-size: 18px;
  margin-top: -12px;
}

.password-toggle-icon:hover {
  color: #495e95;
}

.login-bttn {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  background-color: #495e95;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  height: 45px;
}

.login-bttn:hover {
  background-color: #3b497f;
}

.login-links {
  margin-top: 20px;
}

.login-links p {
  font-size: 14px;
  color: #6c757d;
}

.login-links a {
  color: #495e95;
  text-decoration: none;
}

.login-links a:hover {
  text-decoration: underline;
}
.login-title{
  font-size: 28px;
  color: #425A8B;
  margin-bottom: 10px;
 
}
.error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.form-error {
  text-align: center;
  margin-top: 10px;
}
@media (max-width: 768px) and (min-width: 425px) {
  .login-title {
    width: 100%;
      font-size: 24px; 
  }

  .form-login input  {
      width: 100%;
      padding: 8px; 
      height: 36px; 
  }

  .login-bttn { 
      
      padding: 8px; 
      height: 40px; 
      font-size: 16px; 
  }

  
  .login-links p {
      font-size: 12px; 
  }
}

@media (max-width: 425px) {
.login-container{
  width: 100%;
  padding: 0;
  
}
  .login-title {
      font-size: 20px; 
      
  }

  .form-login input {
    
      
      height: 34px; 
      padding: 6px; 
  }

  .login-bttn {
      height: 38px; 
      font-size: 14px; 
  }

  
  .login-links p {
      font-size: 11px; 
  }
}
