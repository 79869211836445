.main {
  display: flex;
  justify-content: center;
  gap: 40px;

}

.form-container {
  /* width: 45%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 40px; */
  border-radius: 10px;

}

.form-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #425A8B;
}

.form-row {
  display: flex;
}

.form-row input {
  line-height: 30px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 5px 0;
}

.form-row input[type="text"] {
  width: 100%;
  max-width: 242px;
}

.form-row input[type="tel"] {
  width: 100%;
  max-width: 500px;
}

.form-row input[type="email"],
.form-row input[type="password"] {
  width: 100%;
  max-width: 500px;
}

.store-check {
  display: flex;
  align-items: start;
  margin-bottom: 10px;
}

.store-check label {
  font-size: 14px;
  color: #333;
  display: block;
}

.store-check input[type="checkbox"] {
  margin-right: 5px;
  vertical-align: middle;
  width: auto;
}

.store-check a {
  color: #0BA9ED;
  text-decoration: none;
  margin-left: 5px;
}

.kayit-btn {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  background-color: #34b38a;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 20px;
}

.kayit-btn:hover {
  background-color: #2a8f70;
}

.store-login {
  /* width: 45%; */
  text-align: center;
  margin-top: 100px;

}

.store-login h2 {
  margin-bottom: 10px;
  font-size: 28px;
  color: #425A8B;
}

.store-login p {
  margin-bottom: 20px;
  color: #6c757d;
  padding-bottom: 20px;
}

.features {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.feature-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 200px;

}

.feature-item p {
  text-align: center;

}

.icon {
  font-size: 36px;
  margin-bottom: 10px;
}

.login-btn {
  width: 100%;
  max-width: 500px;
  padding: 12px;
  background-color: #425A8B;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.login-btn:hover {
  background-color: #425A8B;
}

/* Modal stilleri */
.Modal {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  max-width: 800px;
  width: 80%;
  max-height: 80vh;
  overflow-y: auto;
  outline: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  color: #425A8B;
}

.close-button:hover {
  color: #333;
}

.Modal h2 {
  color: #425A8B;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.Modal p {
  margin-bottom: 15px;
  line-height: 1.6;
}


@media (max-width: 768px) {
  .main {
    flex-direction: column;
    gap: 10px;
  }

  .form-container {
    padding: 10px;
  }

  .register-container {
    flex-direction: column;
    align-items: center;
  }

  .register-form,
  .store-login {
    width: 100%;
    margin-bottom: 20px;
  }

  .store-login .features {
    display: none;
  }

  .store-login {
    margin: 0;
  }

  .store-login h2 {
    margin: 0;
    font-size: 18px;
  }

  .store-login p {
    font-size: 14px;
    margin: 0;
  }

  .store-login button {
    width: 60%;
  }

  .input-group {
    flex-direction: column;
  }

  .input-group input {
    width: 100%;
  }

  .register-btn,
  .login-btn {
    width: 90%;
  }

  .store-check {
    align-items: center;
  }

  .consent-label {
    font-size: 12px !important;
  }

  .Modal {
    width: 90%;
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .consent-label {
    font-size: 11px !important;
  }

  .Modal {
    width: 95%;
    padding: 10px;
  }
}