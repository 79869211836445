.search-results-title {
 padding-left: 50px;

}
.search-result {
  display: flex;
  flex-wrap: wrap;
}
  
.search-result-product {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
}

.search-result-grid {
    display: flex;                
    flex-wrap: wrap;             
    justify-content: center;      
    gap: 10px;   
}

.search-result-card {
    border: 1px solid #ddd;      
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    position: relative;
    text-align: left;
    white-space: nowrap; 
    text-overflow: ellipsis; 
     
}
.search-result-card:hover{
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
.search-result-img-container {
    position: relative;            
    width: 220px;                 
    height: 220px;               
    overflow: hidden;              
}

.search-result-img-container img {
    width: 100%;                  
    height: 100%;                 
    object-fit: cover;   
    transition: transform 0.3s ease;         
}
.search-result-card:hover img{
    transform: scale(1.05);
}

.search-result-responsive-info {
    margin-left: 10px;
}
  
.search-result-responsive-name{
  font-size: 14px;
  overflow: hidden; 
  width: 200px; 
  white-space: nowrap; 
  text-overflow: ellipsis; 
  color: #425A8B;
  transition: color 0.3s ease;
  margin-block-start: 10px;
  margin-block-end: 5px;
}

.search-result-pricee {
    font-size: 16px;
    font-weight: bold;
    color:  #425A8B;
    margin-block-start: 10px;
    margin-block-end:10px;
    display: flex;
    align-items: left;
}
    
.search-result-originall-price {
       
    color: #333333;
    margin-right: 8px;
    font-size: 16px;
    font-weight: bold;
     margin-block-start: 0;
     margin-block-end: 0;
}
.search-result-originall-price.text-decoration-line-through {
    text-decoration: line-through;
}
.search-result-discountedd-price {
    color: #e63946; 
    font-weight: bold;
    font-size: 16px;
    margin-block-start: 0;
    margin-block-end: 0;
}
 
.rating {
    color: #ff9800;
    font-size: 16px;
  }
    @media (max-width: 768px) {
        
        .search h2 {
            padding-left: 5px;  
            font-size: 18px;     
        }
    
        
        .search-result {
            display: block;     
        }
    
       
        .search-result-product {
            align-items: center;  
            margin-bottom: 15px;   
        }
    
        .search-result-grid {
            display: grid;          
            grid-template-columns: repeat(3, 1fr); 
            gap: 10px;              
        }
    
        .search-result-card {
            width: 100%;         
            margin-bottom: 10px; 
        }
    
      
        .search-result-img-container {
            width: 100%;    
            height: 180px;  
        }
    
       
        .search-result-img-container img {
            object-fit: cover;  
        }
    

    
        .search-result-responsive-info {
            margin-left: 0;  
            margin-top: 10px; 
        }
    
        .search-result-responsive-name {
            font-size: 14px;
            width: 100%;  
            overflow: hidden;
            text-overflow: ellipsis; 
        }
    
        .search-result-responsive-name a {
            font-size: 14px;
            text-decoration: none;
            color: #425A8B;
            display: block;    
            margin-top: 5px;  
        }
    
        .search-result-responsive-name a:hover {
            color: #2b4a6f;    
        }
    }
    @media (max-width: 425px) {
        
        .search h2 {
            padding-left: 5px;  
            font-size: 18px;     
        }
    
        
        .search-result {
            display: block;     
        }
    
       
        .search-result-product {
            align-items: center;  
            margin-bottom: 15px;   
        }
    
        .search-result-grid {
            display: grid;          
            grid-template-columns: repeat(2, 1fr); 
            gap: 10px;              
        }
    
        .search-result-card {
            width: 100%;         
            margin-bottom: 10px; 
        }
    
      
        .search-result-img-container {
            width: 100%;    
            height: 180px;  
        }
    
       
        .search-result-img-container img {
            object-fit: cover;  
        }
    

    
        .search-result-responsive-info {
            text-align: center;
        }
    
        .search-result-responsive-name {
            font-size: 14px;
            width: 100%;  
            overflow: hidden;
            text-overflow: ellipsis; 
        }
    
        .search-result-responsive-name a {
            font-size: 14px;
            text-decoration: none;
            color: #425A8B;
            display: block;    
            margin-top: 5px;  
        }
    
        .search-result-responsive-name a:hover {
            color: #2b4a6f;    
        }
        .search-result-pricee {
            justify-content: center;
        }
            
        .search-result-originall-price {
               
            font-size: 16px;
            margin-right: 0;
        }
        .search-result-originall-price.text-decoration-line-through {
            text-decoration: line-through;
        }
        .search-result-discountedd-price {
            font-size: 16px;
        }
    }