.shared-cart-container {
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
}

.shared-cart-header {
  text-align: center;
  margin-bottom: 30px;
}

.shared-cart-header h1 {
  font-size: 28px;
  margin-bottom: 10px;
  color: #333;
  font-weight: 600;
}

.shared-cart-header p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.shared-cart-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
}

.shared-cart-item {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 450px;
}

.shared-cart-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.item-image {
  width: 100%;
  aspect-ratio: 4/5;
  overflow: hidden;
  position: relative;
}

.item-image img {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
  object-fit: cover;
}

.shared-cart-item:hover .item-image img {
  transform: scale(1.05);
}

.item-details {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
  width: 100%;
}

.item-details h3 {
  font-size: 14px;
  margin-block-start: 5px;
  margin-block-end: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #425A8B;
}

.item-size {
  font-size: 12px;
  color: #666;
  margin-bottom: 8px;
  background-color: #f5f5f5;
  display: inline-block;
  padding: 3px 8px;
  border-radius: 4px;
  align-self: flex-start;
}

.item-price {
  font-size: 18px;
  font-weight: bold;
  margin-block-start: 5px;
  margin-block-end: 10px;
  padding: 0 10px;
}

.ratingg-shared-cart {
  color: #ff9800;
  font-size: 16px;
  margin-bottom: 5px;
  padding: 0 10px;
}

.shared-cart-actions-bottom {
  display: flex;
  justify-content: center;
  gap: 15px;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #eee;
  flex-wrap: wrap;
}

.shared-cart-continue-btn,
.shared-cart-add-btn,
.shared-cart-buy-btn {
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s;
  font-size: 16px;
  min-width: 140px;
  text-align: center;
}

.shared-cart-continue-btn {
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ddd;
}

.shared-cart-continue-btn:hover:not(:disabled) {
  background-color: #e8e8e8;
}

.shared-cart-add-btn:disabled,
.shared-cart-buy-btn:disabled,
.shared-cart-continue-btn:disabled {
  background-color: #cccccc;
  color: #888888;
  cursor: not-allowed;
  opacity: 0.7;
}

.shared-cart-add-btn {
  background-color: #4caf50;
  color: white;
}

.shared-cart-add-btn:hover:not(:disabled) {
  background-color: #388e3c;
}

.shared-cart-buy-btn {
  background-color: #f57c00;
  color: white;
}

.shared-cart-buy-btn:hover:not(:disabled) {
  background-color: #ef6c00;
}

.shared-cart-error,
.shared-cart-empty,
.shared-cart-loading {
  text-align: center;
  padding: 40px 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  margin: 30px auto;
  max-width: 600px;
}

.error-icon,
.empty-icon {
  margin: 0 auto 20px;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shared-cart-error h2,
.shared-cart-empty h2,
.shared-cart-loading h2 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #333;
  font-weight: 600;
}

.shared-cart-error p,
.shared-cart-empty p,
.shared-cart-loading p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
  line-height: 1.5;
}

/* Büyük ekranlar için */
@media (max-width: 1200px) and (min-width: 901px) {
  .shared-cart-items {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
}

/* Orta boy ekranlar için */
@media (max-width: 900px) and (min-width: 601px) {
  .shared-cart-items {
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
  }
}

/* Tablet ekranlar için */
@media (max-width: 768px) and (min-width: 426px) {
  .shared-cart-container {
    width: 100%;
  }

  .shared-cart-items {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  .item-image {
    aspect-ratio: 1/1;
  }

  .item-price {
    font-size: 16px;
  }

  .shared-cart-header h1 {
    font-size: 24px;
  }

  .shared-cart-header p {
    font-size: 14px;
  }

  .shared-cart-error,
  .shared-cart-empty,
  .shared-cart-loading {
    padding: 30px 15px;
    margin: 20px auto;
  }

  .error-icon,
  .empty-icon {
    width: 60px;
    height: 60px;
  }

  .shared-cart-error h2,
  .shared-cart-empty h2,
  .shared-cart-loading h2 {
    font-size: 20px;
  }

  .shared-cart-error p,
  .shared-cart-empty p,
  .shared-cart-loading p {
    font-size: 14px;
  }

  .shared-cart-actions {
    flex-direction: column;
    gap: 10px;
  }

  .shared-cart-button {
    width: 100%;
  }

  .shared-cart-message {
    margin: 15px 0;
    padding: 10px 15px;
  }

  .shared-cart-message p {
    font-size: 14px;
  }
}

/* Mobil ekranlar için */
@media (max-width: 767px) {
  .shared-cart-item {
    position: relative;
    flex-direction: column;
  }

  .item-image {
    width: 100%;
    aspect-ratio: 1/1;
  }

  .item-checkbox {
    top: 15px;
    left: 15px;
  }

  .size-select {
    max-width: 130px;
    padding: 6px;
  }

  .shared-cart-actions {
    flex-direction: column;
  }

  .shared-cart-button {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 425px) {
  .shared-cart-items {
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
  }

  .shared-cart-item {
    border-radius: 6px;
  }

  .item-image {
    aspect-ratio: 1/1;
  }

  .item-details {
    padding: 8px 0px;
  }

  .item-size {
    font-size: 10px;
    padding: 2px 6px;
    margin-bottom: 4px;
  }

  .item-price {
    font-size: 14px;
    margin-block-start: 3px;
    margin-block-end: 5px;
  }

  .shared-cart-actions-bottom {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
    padding-top: 15px;
    margin-top: 15px;
  }

  .shared-cart-continue-btn,
  .shared-cart-add-btn,
  .shared-cart-buy-btn {
    width: 100%;
    padding: 10px 15px;
    font-size: 14px;
  }

  .shared-cart-header h1 {
    font-size: 20px;
  }

  .shared-cart-header p {
    font-size: 12px;
  }

  .shared-cart-actions {
    margin-top: 20px;
  }

  .shared-cart-button {
    padding: 10px 20px;
    font-size: 14px;
  }
}

/* Çok küçük ekranlar için */
@media (max-width: 320px) {
  .shared-cart-items {
    grid-template-columns: repeat(1, 1fr);
  }

  .item-image {
    aspect-ratio: 3/2;
  }
}

.shared-cart-message {
  background-color: #f8f9fa;
  border-left: 4px solid #425A8B;
  padding: 12px 20px;
  margin: 20px 0;
  border-radius: 4px;
  text-align: center;
  animation: fadeIn 0.3s ease-in;
}

.shared-cart-message p {
  margin: 0;
  color: #333;
  font-size: 16px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.shared-cart-actions {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 30px;
  flex-wrap: wrap;
}

.shared-cart-button {
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.2s;
  font-size: 16px;
  min-width: 160px;
  text-align: center;
}

.shared-cart-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.add-to-cart {
  background-color: #425A8B;
  color: white;
}

.add-to-cart:hover:not(:disabled) {
  background-color: #344a77;
}

.buy-now {
  background-color: #ff6b6b;
  color: white;
}

.buy-now:hover:not(:disabled) {
  background-color: #ff5252;
}

.continue-shopping {
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ddd;
}

.continue-shopping:hover {
  background-color: #e8e8e8;
}

.favorite-icon-shared-cart {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 10;
  visibility: visible !important;
  opacity: 1 !important;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  display: block !important;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  padding: 8px;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }

  50% {
    transform: scale(1.1);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0.7;
  }
}

.select-all-container {
  display: flex;
  margin-bottom: 15px;
}

.select-all-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.select-all-label input[type="checkbox"] {
  margin-right: 8px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.select-all-text {
  font-weight: 500;
}

.item-checkbox {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2;
  background-color: transparent;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-checkbox input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

/* Size seçim alanı için stiller */
.item-size-container {
  margin: 0px;
  display: flex;
  align-items: center;
  gap: 6px;
  position: relative;
  z-index: 15;
  background-color: transparent;
  border-radius: 6px;
}

.item-details-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.size-label {
  font-size: 14px;
  margin-bottom: 8px;
  font-weight: 600;
  display: block;
  color: #425A8B;
}

.size-select {
  border: 1px solid lightgray;
  border-radius: 4px;
  background-color: transparent;
  font-size: 14px;
  appearance: auto;
  z-index: 20;
  position: relative;
  cursor: pointer;
}

.size-select-label {
  font-size: 14px;
  font-weight: 600;
  display: block;
  color: #425A8B;
}

.size-price-difference-shared-cart {
  font-size: 13px;
  color: #ff0000;
  margin: 0 0 10px 0;
  padding: 0 10px;
  font-weight: 500;
  display: inline-block;
}

/* Toplam fiyat özeti için stil */
.shared-cart-summary {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.summary-item-total {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 18px;
}

.total-price {
  color: #ff5722;
}

@media (max-width: 767px) {
  .shared-cart-item {
    flex-direction: column;
  }

  .item-image {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
    height: auto;
    aspect-ratio: 4 / 5;
  }

  .item-checkbox {
    margin-bottom: 10px;
    justify-content: flex-start;
  }

  .shared-cart-actions {
    flex-direction: column;
  }

  .shared-cart-button {
    width: 100%;
    margin-bottom: 10px;
  }
}