.toast-container {
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .toast {
    padding: 15px 25px;
    border-radius: 4px;
    color: white;
    display: flex;
    align-items: center;
    gap: 15px;
    animation: slideIn 0.3s ease-out;
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  
  .toast-info { background: #2196f3; }
  .toast-success { background: #4caf50; }
  .toast-error { background: #f44336; }
  .toast-warning { background: #ff9800; }
  
  .toast-close {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    margin-left: auto;
    padding: 0;
  }