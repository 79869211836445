.payment-page {
  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.6s ease;
  padding: 12px;
}

.payment-page.form-active {
  justify-content: flex-start;
  padding-left: 15%;
}

.payment-error-message {
  color: #dc3545;
  font-size: 14px;
  margin: 15px 0;
  margin-block-start: 10;
  margin-block-end: 0;
}

.payment-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.payment-title h2 {
  display: flex;
  justify-content: center;

}

.payment-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  /* flex-wrap: wrap;   */
  /* background-color: #fff; */
  /* padding: 15px; */
  /* border-radius: 5px; */
}

.bank-img {
  width: 40px;
  height: auto;
  padding-left: 5px;
  padding-top: 3px;
}

.payment-right {
  max-width: 420px;
  width: 100%;

}

.payment-form {
  /* max-width: 400px; */
  /* width: 100%; */
  /* background: #ffffff; */
  /* padding: 30px; */
  /* border-radius: 12px; */
  /* box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); */
  transition: transform 0.6s ease, opacity 0.4s ease;
  margin: 0;
  /* margin-top: 15px; */
}

.form-group {
  margin-bottom: 15px;
  margin-right: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group input:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 8px rgba(59, 130, 246, 0.2);
  outline: none;
}

.form-group input:disabled {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  color: #666;
  cursor: not-allowed;
}

.form-group input:disabled:focus {
  outline: none;
  box-shadow: none;
}

.form-row-bottom {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
  padding: 0px !important;
}

.form-row-bottom input {
  margin-right: 0px;
}

.small-group {
  width: 48%;
}

.payment-form .small-group {
  display: inline-block;
}

.small-group input {
  height: 37px;
}

.commission-info h2 {
  margin-bottom: 20px;
  padding-left: 20px;
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.commission-info button {
  display: block;
  margin: auto;
  margin-bottom: 20px;
  padding: 12px 20px;
  font-size: 14px;
  font-weight: bold;
  color: #003366;
  background: none;
  border: 2px solid #003366;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.commission-info button:hover {
  background-color: #003366;
  color: #fff;
  transform: scale(1.05);
}

.ReactModal__Content h2 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
  color: #425A8B;
}

.ReactModal__Overlay table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.ReactModal__Overlay table th {
  background-color: #f4f6f9;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  border: 1px solid #ddd;
}

.ReactModal__Overlay table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: center;
  vertical-align: middle;
}

.ReactModal__Overlay table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.ReactModal__Overlay table tr:hover {
  background-color: #f1f1f1;
}

.payment-button {
  width: 100%;
  padding: 14px;
  font-size: 16px;
  color: white;
  background: #003366;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 16px;

}

.payment-button:hover {
  background: #0a4988;
}

.payment-button:disabled {
  background-color: #ccc;
}

.installment-options {
  max-width: 650px;
  /* width: 100%; */
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.installment-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.installment-table th,
.installment-table td {
  padding: 14px;
  border: 1px solid #e2e8f0;
  text-align: left;
  font-size: 14px;
  color: #4a5568;
}

.installment-table th {
  background-color: #f7fafc;
  font-weight: 600;
  color: #2d3748;
}

.installment-table tr:nth-child(even) {
  background-color: #f9f9fb;
}

.installment-table tr:hover {
  background-color: #edf2f7;
}

.installment-table td input[type="radio"] {
  accent-color: #3182ce;
  margin-right: 8px;
}

.installment-table td label {
  font-weight: 600;
  cursor: pointer;
  color: #2d3748;
}

.installment-table td span {
  color: #e53e3e;
  font-weight: bold;

}

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.ReactModal__Content {
  background-color: #fff;
  padding: 30px;
  border-radius: 12px;
  width: 600px;
  max-width: 90%;
  max-height: 70vh;
  overflow-y: auto;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.4);
  position: relative;
  z-index: 1001;
  font-family: 'Arial', sans-serif;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: #f0f0f0;
  border: none;
  font-size: 24px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.close-button:hover {
  background-color: #e0e0e0;
  transform: scale(1.1);
}

/* Küçük ekranlar için düzenlemeler */
@media (max-width: 768px) {
  .payment-page {
    flex-direction: column;
    /* padding: 10px; */
  }

  .payment-page.form-active {
    padding-left: 0;
    justify-content: center;
  }

  .payment-title h2 {
    font-size: 20px;
  }

  .payment-container {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .payment-form,
  .payment-right {
    max-width: 100%;
    width: 100%;
  }

  /* .payment-form {
    padding: 20px;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  } */

  .form-group {
    margin-bottom: 10px;
  }


  .small-group {
    width: 100%;
    margin-bottom: 15px;
  }

  .bank-img {
    width: 30px;
    padding-left: 0;
    padding-top: 0;
  }

  .commission-info h2 {
    font-size: 18px;
    padding-left: 10px;
  }

  .commission-info button {
    font-size: 13px;
    padding: 10px 15px;
  }

  .ReactModal__Content {
    width: 90%;
    max-height: 80vh;
  }

  .close-button {
    width: 35px;
    height: 35px;
    font-size: 20px;
    line-height: 32px;
  }

  .installment-options {
    padding: 15px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  }

  .installment-table th,
  .installment-table td {
    padding: 10px;
    font-size: 12px;
  }

  .payment-button {
    font-size: 14px;
    padding: 12px;
  }

  .invoice-form {
    grid-template-columns: 1fr;
    gap: 0;
    padding: 0px;
  }

  .invoice-form .form-group textarea {
    min-height: 120px;
  }

  .payment-information-header h3 {
    font-size: 16px;
  }

  .invoice-info-text {
    font-size: 13px;
    padding: 8px;
  }
}

@media (max-width: 425px) {
  .payment-page {
    flex-direction: column;
    padding: 6px;
    gap: 6px;
    overflow-x: hidden;
  }

  .payment-page.form-active {
    padding-left: 0;
    justify-content: center;
  }

  .payment-title h2 {
    font-size: 16px;
    text-align: center;
    word-wrap: break-word;
  }

  .payment-container {
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .payment-form,
  .payment-right {
    max-width: 350px;
    width: 100%;
  }

  /* .payment-form {
    padding: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  } */

  .form-group input,
  .form-group select {
    width: 100%;
    font-size: 14px;
    padding: 8px;
  }

  .small-group {
    width: 100%;
    margin-bottom: 10px;
  }

  .bank-img {
    width: 25px;
    height: auto;
    padding-left: 0;
    padding-top: 0;
  }

  .commission-info h2 {
    font-size: 14px;
    padding-left: 5px;
    text-align: center;
    word-wrap: break-word;
  }

  .commission-info button {
    font-size: 12px;
    padding: 8px 12px;
  }

  .ReactModal__Content {
    width: 90%;
    max-height: 80vh;
    padding: 15px;
    overflow-y: auto;
  }

  .close-button {
    width: 30px;
    height: 30px;
    font-size: 18px;
    line-height: 28px;
  }

  .installment-options {
    padding: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .installment-table th,
  .installment-table td {
    padding: 8px;
    font-size: 12px;
  }

  .installment-table td input[type="radio"] {
    margin-right: 5px;
  }

  .payment-button {
    font-size: 14px;
    padding: 10px;
    width: 100%;
  }

  .payment-title h2,
  .commission-info h2 {
    margin-bottom: 10px;
  }

  .form-group label {
    font-size: 14px;
  }

  .invoice-form {
    gap: 10px;
    padding: 0px;
  }

  .invoice-form textarea {
    min-height: 150px;
  }

  .invoice-info-text {
    font-size: 12px;
    padding: 6px;
    margin: 8px 0 16px;
  }
}

.read-only-input {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  color: #666;
  cursor: not-allowed;
  font-weight: bold;
}

.read-only-input:focus {
  outline: none;
  border-color: #ddd;
  box-shadow: none;
}

.customer-name-info {
  margin-bottom: 20px;
  padding: 12px;
  background-color: #f8f9fa;
  border-radius: 6px;
  border-left: 4px solid #425A8B;
}

.customer-name-info p {
  margin: 0;
  font-size: 14px;
  color: #333;
}

.customer-name-info strong {
  color: #425A8B;
  font-weight: 600;
}

.name-error {
  display: block;
  color: #f44336;
  font-size: 14px;
  margin-top: 5px;
  font-weight: 500;
}

.eft-payment-info {
  max-width: 600px;
  margin: 20px auto;
  padding: 25px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.eft-payment-info h3 {
  margin-top: 0;
  color: #425A8B;
  font-size: 20px;
  margin-bottom: 15px;
}

.eft-payment-info p {
  margin-bottom: 20px;
  color: #555;
  line-height: 1.5;
}

.bank-account-info {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
  text-align: left;
}

.bank-account-info div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.bank-account-info p {
  margin: 8px 0;
  font-size: 15px;
  color: #333;
}

.bank-account-info strong {
  color: #003366;
  font-weight: 600;
}

.eft-important-notice {
  padding: 15px;
  background-color: #fff9e6;
  border: 1px solid #f0e0a2;
  border-radius: 8px;
  margin-bottom: 20px;
}

.eft-important-notice p {
  margin: 10px 0;
  color: #856404;
  font-size: 14px;
  line-height: 1.5;
}

.eft-important-notice strong {
  color: #e53e3e;
  font-weight: bold;
}

.eft-error-message {
  margin: 15px 0;
  padding: 10px 15px;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 8px;
  color: #721c24;
}

.eft-error-message p {
  margin: 5px 0;
  font-size: 14px;
  line-height: 1.5;
}

.eft-confirm-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.eft-confirm-button {
  background-color: #425A8B;
  color: white;
  border: none;
  padding: 12px 25px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  /* margin-top: 14px; */
}

.eft-confirm-button:hover {
  background-color: #324878;
}

.eft-confirm-container p {
  color: #425A8B;
  font-size: 14px;
  padding: 0px 10px;
  text-align: center;
  margin-top: 4px;
}

.payment-confirm-bottom {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.order-details {
  margin: 20px 0;
  padding: 15px;
  background-color: #f5f9ff;
  border: 1px solid #d6e4ff;
  border-radius: 8px;
  text-align: center;
}

.order-details p {
  margin: 10px 0;
  font-size: 16px;
  color: #333;
}

.order-details strong {
  color: #003366;
  font-weight: 600;
  font-size: 18px;
}

/* Kopyalama butonu stilleri */
.copy-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  padding: 4px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: #003366;
}

.copy-button:hover {
  background-color: #003366;
  color: white;
}

.copy-button i {
  margin-right: 5px;
}

.copy-button span {
  font-size: 14px;
}

/* Kopyalama başarılı mesajı */
.copy-success-message {
  background-color: #d4edda;
  color: #155724;
  padding: 10px 15px;
  margin: 10px 0;
  border-radius: 4px;
  text-align: center;
  animation: fadeOut 2s forwards;
  animation-delay: 1.5s;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

/* Bank account info ve order details düzenlemeleri */
.bank-account-info p,
.order-details p {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  gap: 14px;
}

/* Fatura formu stilleri */
.invoice-information {
  display: flex;
  /* justify-content: center; */
  background-color: #f9f9f9;
  /* border-radius: 0px 0px 8px 8px; */
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0 14px;
}

.invoice-info-text {
  /* margin: 10px 0 20px; */
  margin: 0;
  padding: 6px 10px;
  background-color: #f0f7ff;
  border-left: 4px solid #3b82f6;
  color: #334155;
  font-size: 14px;
  line-height: 1.5;
  /* border-radius: 0 4px 4px 0; */
}

.invoice-form {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  padding: 12px;
}

/* Adres alanını her zaman tam genişlikte göster */
.invoice-form .form-group:last-child {
  grid-column: 1 / -1;
}

.invoice-form .form-group textarea {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
  font-family: inherit;
  line-height: 1.5;
}

.order-details-info {
  color: red !important;
  background-color: rgb(248, 231, 231) !important;
  padding: 10px !important;
  border-radius: 6px !important;
}

/* .payment-information-section { */
/* margin-top: 30px; */
/* margin-bottom: 30px; */
/* padding: 20px; */
/* border: 1px solid #e0e0e0; */
/* border-radius: 5px; */
/* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
/* background-color: #fff; */
/* max-width: 470px; */
/* } */

.payment-information-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #334155;
  color: white;
  border-radius: 5px 5px 0 0;
}

.payment-information-header h3 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
}

.payment-information-content {
  padding: 20px;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
}

.error {
  color: #e74c3c;
  font-size: 12px;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .invoice-information {
    flex-direction: column;
  }

  .bank-account-info div {
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 4px;
  }

  .copy-button {
    margin-left: 0px;
  }

  .bank-account-info p,
  .order-details p {
    margin: 0px 6px 6px 6px;
  }

  .invoice-form {
    padding: 10px 0px;
  }
}

@media (max-width: 400px) {
  .invoice-form {
    grid-template-columns: 1fr;
  }
}