.campaign-container {
    padding: 15px;
    text-align: center;
    background: linear-gradient(to right, #f5a623, #f093fb);
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 50px;
}
.campaign-title{
    display: flex;
    justify-content: space-between; 
    align-items: center;
    margin-bottom: 10px; 
}
.campaign-title h2 {
    color: #fff; 
    margin: 0; 
    font-size: 22px;
}
.campaign-title h3 {
    color: #fff; 
    transition: color 0.3s ease;
    margin: 0; 
    font-weight: normal;
}
.campaign-title h3 a {
    text-decoration: none; 
    color: inherit; 
    display: flex;   
}
.campaign-products-wrapper {
    position: relative;
    display: flex;
    
}
.campaign-products {
    display: flex;
    gap: 10px;
    overflow: hidden;
    scroll-behavior: smooth;
    padding: 10px 0;
    width: 100%;
}

.campaign-product-card {
    width: 100%;
    max-width: 200px;
    flex-shrink: 0;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transition: transform 0.2s, box-shadow 0.2s;
    border-radius: 12px;
    background-color: #f9f9f9;
    position: relative;
    text-align: left;
}
.campaign-product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}
.campaign-product-card img {
    width: 100%;
    aspect-ratio:4/5;
    
    object-fit: cover;
}

.favorite-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-size: 16px;
    z-index: 10;
    visibility: visible!important;  
    opacity: 1 !important;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    display: block !important; 
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    padding: 8px;
}

.campaign-responsive-name {
    font-size: 14px;
    color: #333;
    margin-block-start: 5px;
    margin-block-end: 0;
    padding-left: 10px;
    white-space: nowrap; 
    text-overflow: ellipsis; 
    overflow: hidden;
    
}
.campaign-responsive-name a {
    text-decoration: none;
    color: #425A8B;
    transition: color 0.3s ease;
  }
  .campaign-responsive-name a:hover {
    color: #425A8B
  }
  .ratings {
    color: #ff9800;
    font-size: 20px;
    padding-left: 10px;
  }

.price {
    margin-top: 5px;
    display: flex;
    align-items: left;
    padding-left: 12px;
    margin-bottom: 10px;
}

.original-price {
    text-decoration: line-through;
    color: #333333;
    margin-right: 8px;
    font-size: 16px;
    font-weight: bold;
}

.discounted-price {
    color: #AF1321;
    font-weight: bold;
    font-size: 16px;
}
.scroll-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color:white;
    border: none;
    color: black;
    padding: 12px;
    font-size: 24px;
    cursor: pointer;
    border-radius: 100%;
    z-index: 1;
    box-shadow:0 4px 15px rgba(0, 0, 0, 0.1); ;
}

.scroll-button.left {
    left: -25px;
}

.scroll-button.right {
    right: -25px;
}

.scroll-button:hover {
    background-color: rgb(255, 136, 0);
    transition: background .5s;
}
.scroll-button:hover svg{
    color: white;
}
@media (max-width: 768px) {
    .campaign-title {
        flex-direction: column;
        text-align: center;
    }
    .campaign-products {
        gap: 5px;
    }
    .campaign-product-card {
        max-width: 150px;
    }
    .campaign-responsive-name {
        font-size: 11px;
    }
    .price {
        font-size: 10px;
    }
    .original-price {
        text-decoration: line-through;
        color: #333333;
        margin-right: 8px;
        font-size: 12px;
        font-weight: bold;
    }
    
    .discounted-price {
        color: #e63946;
        font-weight: bold;
        font-size: 12px;
    }
    .ratings {
        
        font-size: 14px;
       
        
      }
      
}
