.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 350px;
  }
  .popup-icon {
    width: 80px;
    margin-bottom: 20px;
  }
  
  .popup-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .popup-buttons button {
    width: 45%;
    padding: 8px 0px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center; 
    justify-content: center;
  }
  
  .continue-btn {
    background-color: #425A8B;
    color: white;
  }
  
  .go-cart-btn {
    background-color: #0eaa49;
    color: white;
  }