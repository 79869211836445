.store-logos-container {
  overflow: hidden;
  display: flex;
  margin-top: 5px;
  position: relative;
  width: 96.5%;
  margin-left: 25px;



}

.slider-container {
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
}

.slider {
  display: flex;
  width: 100%;
  animation: scroll 30s linear infinite;
  cursor: grab;
  user-select: none;
}


@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

.store-logo-link {
  flex: 0 0 auto;
  text-decoration: none;
  color: inherit;
  margin: 5px;
  transition: all 0.3s ease 0s;
  animation: slide 20s linear infinite;
  padding-left: 22px;

}


.store-logo-item {
  display: flex;
  overflow: hidden;
  width: 100px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 8px 6px, rgba(0, 0, 0, 0.23) 0px 3px 3px;
  background-color: linear-gradient(to right, #ffd85e 0%, #fe929c 50%, #de8dff 100%);
  transition: transform 0.3s ease;

}

.store-logo-item:hover {
  transform: scale(1.1);
}

.store-logo {
  object-fit: contain;
  width: 100px;
  height: 100px;
  border-radius: 50%;


}

.storep {
  margin-top: 5px;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  color: #425A8B;
  width: 100px;
}

@media (max-width: 425px) {
  .slider {
    animation: scroll 8s linear infinite;
  }

  .store-logo-item {
    width: 60px;
    height: 60px;
  }

  .store-logo {
    width: 60px;
    height: 60px;
  }

  .storep {
    font-size: 9px;
    width: 60px;
  }

  .store-logo-link {
    margin: 2px;
    padding-left: 8px;
  }

  .store-logos-container {
    width: 95%;
    margin-left: 0;
  }

  .product-list-container {
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 600px) and (min-width: 425px) {

  .slider {
    animation: scroll 10s linear infinite;
  }

  .store-logo-item {
    width: 70px;
    height: 70px;
  }

  .store-logo {
    width: 70px;
    height: 70px;
  }

  .storep {
    font-size: 10px;
    width: 70px;
  }

  .store-logos-container {
    width: 96.5%;
    margin-left: 0;

  }

  .product-list-container {
    flex-direction: column;
    align-items: center;
  }

  .store-logo-link {
    margin: 3px;
    padding-left: 10px;
  }
}


@media (min-width: 600px) and (max-width: 960px) {
  .slider {
    animation: scroll 15s linear infinite;
  }

  .store-logo-item {
    width: 90px;
    height: 90px;
  }

  .store-logos-container {
    width: 93%;
  }

  .store-logo {
    width: 90px;
    height: 90px;
  }

  .storep {
    font-size: 11px;
    width: 90px;
  }

}


@media (max-width: 1600px) {
  .slider {
    animation: scroll 20s linear infinite;
  }

  .store-logo-item {
    width: 80px;
    height: 80px;
  }

  .store-logo {
    width: 80px;
    height: 80px;
  }

  .store-logos-container {
    width: 96.5%;


  }

  .storep {
    font-size: 10px;
    width: 80px;
  }
}