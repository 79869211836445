.image-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.main-image {
    width: 450px;
    max-width: 100%;
    cursor: zoom-in;
    border-radius: 5px;
}

.main-image img {
    width: 100%;
    aspect-ratio: 4/5;
    object-fit: cover;
    border-radius: 5px;
    transition: transform 0.3s ease;
}

.zoomed-image {
    background-size: 200%;
    position: fixed;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.9);
    border: 1px solid #ccc;
    z-index: 1000;
    padding: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    pointer-events: none;
    background-repeat: no-repeat;
    object-fit: cover;
}

.carousel-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    font-size: 24px;
    border-radius: 50%;
    position: absolute;
    transform: translateY(-50%);
    z-index: 5;
    top: 40%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    opacity: 0;
}

.carousel-button.visible,
.image-gallery:hover .carousel-button {
    opacity: 0.8;
}

.carousel-button:hover {
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    opacity: 1;
    transform: translateY(-50%) scale(1.1);
}

.carousel-button:active {
    transform: translateY(-50%) scale(0.95);
}

.carousel-button.prev {
    left: 10px;
}

.carousel-button.next {
    right: 10px;
}

.thumbnail-container {
    display: flex;
    margin-top: 10px;
    justify-content: flex-start;
    gap: 10px;
    width: 100%;
    max-width: 800px;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin;
    scrollbar-color: #425A8B #f1f1f1;
    padding: 10px 5px;
    -webkit-overflow-scrolling: touch;
}

.thumbnail-container::-webkit-scrollbar {
    height: 6px;
}

.thumbnail-container::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.thumbnail-container::-webkit-scrollbar-thumb {
    background: #425A8B;
    border-radius: 10px;
}

.thumbnail {
    min-width: 60px;
    height: 60px;
    flex-shrink: 0;
    cursor: pointer;
    border-radius: 3px;
    transition: transform 0.2s;
    object-fit: cover;
}

.thumbnail.active {
    border: 2px solid #425A8B;
    transform: scale(1.1);
    box-shadow: 0 2px 8px rgba(66, 90, 139, 0.4);
}

@media (max-width: 768px) {
    .main-image {
        cursor: default;
    }

    .zoomed-image {
        display: none;
    }

    .carousel-button {
        width: 35px;
        height: 35px;
        font-size: 20px;
        opacity: 1;
    }

    .thumbnail-container {
        max-width: 100%;
    }
    
    .thumbnail {
        min-width: 50px;
        height: 50px;
    }
}

@media (max-width: 425px) {
    .main-image {
        max-width: 350px;
    }

    .carousel-button {
        width: 30px;
        height: 30px;
        font-size: 18px;
        top: 40%;
    }

    .thumbnail-container {
        max-width: 350px;
    }
    
    .thumbnail {
        min-width: 40px;
        height: 40px;
    }
}

@media (max-width: 1600px) {
    .main-image {
        width: 450px;
    }

    .carousel-button {
        width: 40px;
        height: 40px;
        font-size: 24px;
    }

    .thumbnail-container {
        max-width: 450px;
    }
    
    .thumbnail {
        min-width: 70px;
        height: 70px;
    }
}