  .products-container {
    padding: 0px;
    width: 100%;
    margin: auto;
    text-align: left;
  }

  .productt-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }

  .productt-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    position: relative;
    text-align: left;
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .productt-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }

  .productt-card:hover .favorite-icon {
    opacity: 1;
    visibility: visible;
  }

  .favorite-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 10;
    visibility: visible !important;
    opacity: 1 !important;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    display: block !important;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    padding: 8px;
    animation: pulse 2s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 0.7;
    }
  
    50% {
      transform: scale(1.1);
      opacity: 1;
    }
  
    100% {
      transform: scale(1);
      opacity: 0.7;
    }
  }

  .productt-img-container {
    width: 100%;
    aspect-ratio: 4/5;
    overflow: hidden;
    position: relative;
  }

  .productt-card img {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;
    object-fit: cover;
  }

  .productt-card:hover img {
    transform: scale(1.05);
  }

  .productt-card-responsive__info {
    padding: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .productt-card-responsive__name {
    font-size: 14px;
    margin-block-start: 5px;
    margin-block-end: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #425A8B;
  }

  .ratingg {
    color: #ff9800;
    font-size: 16px;
    margin-bottom: 5px;
  }

  .pricee {
    font-size: 18px;
    font-weight: bold;
    color: #425A8B;
    margin-block-start: 5px;
    margin-block-end: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .price-container {
    display: flex;
    flex-direction: column;
  }

  .originall-price {
    color: #333333;
    margin-right: 8px;
    font-size: 18px;
    font-weight: bold;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  .originall-price.text-decoration-line-through {
    text-decoration: line-through;
  }

  .discountedd-price {
    color: #e63946;
    font-weight: bold;
    font-size: 18px;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  .stock-warning {
    margin-top: auto;
    font-size: 14px;
  }

  /* Büyük ekranlar için */
  @media (max-width: 1200px) and (min-width: 901px) {
    .productt-grid {
      grid-template-columns: repeat(4, 1fr);
      gap: 15px;
    }
  }

  /* Orta boy ekranlar için */
  @media (max-width: 900px) and (min-width: 601px) {
    .productt-grid {
      grid-template-columns: repeat(3, 1fr);
      gap: 15px;
    }
  }

  /* Tablet ekranlar için */
  @media (max-width: 768px) and (min-width: 426px) {
    .products-container {
      width: 100%;
    }

    .productt-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }

    .productt-img-container {
      aspect-ratio: 1/1;
    }

    .productt-card-responsive__info {
      padding: 8px;
    }

    .pricee {
      font-size: 16px;
    }

    .originall-price,
    .discountedd-price {
      font-size: 16px;
    }

    .ratingg {
      font-size: 14px;
    }
  }

  /* Mobil ekranlar için */
  @media (max-width: 425px) {
    .productt-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }

    .productt-card {
      border-radius: 6px;
    }

    .productt-img-container {
      aspect-ratio: 1/1;
    }

    .favorite-icon {
      padding: 6px;
      top: 5px;
      right: 5px;
    }

    .productt-card-responsive__info {
      padding: 8px;
    }

    .productt-card-responsive__name {
      font-size: 12px;
      margin-block-start: 3px;
      margin-block-end: 3px;
    }

    .price-container {
      width: 100%;
    }

    .pricee {
      margin-block-start: 3px;
      margin-block-end: 5px;
      flex-direction: column;
      align-items: flex-start;
    }

    .originall-price {
      font-size: 14px;
      margin-right: 0;
      margin-bottom: 2px;
    }

    .discountedd-price {
      font-size: 14px;
    }

    .ratingg {
      font-size: 12px;
    }

    .stock-warning {
      font-size: 12px;
    }
  }

  /* Çok küçük ekranlar için */
  @media (max-width: 320px) {
    .productt-grid {
      grid-template-columns: repeat(1, 1fr);
    }

    .productt-img-container {
      aspect-ratio: 3/2;
    }
  }

  /* Loader için stil */
  .loader-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
  }

  .loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #425A8B;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }