.card-container {
    display: flex;
    justify-content: center;
}

.card {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    width: 90%;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    gap: 10px;
}

.card:hover {
    transform: translateY(-5px);
}

.card-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.card p {
    font-size: 24px;
    text-align: center;
    font-weight: 600;
    color: #333;
}

.card button {
    background-color: #007bff;
    color: white;
    padding: 18px 10px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.card button:hover {
    background-color: #0056b3;
}

.checkout-page {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding: 30px;
}

.card-product {
    width: 100%;
    margin-right: 10px;
}

.product-listt {
    display: flex;
    flex-direction: column;
}

.card-title {
    display: flex;
    align-items: end;
    gap: 10px;
    padding: 0 0 15px 0;
}

.card-title span {
    font-size: 24px;
    color: #425A8B;
    font-weight: bold;
}

.card-title img {
    width: 50px;
    height: 50px;
}


.card-product-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: #425A8B;
}

.card-product-header a {
    text-decoration: none;
    font-weight: bold;
    font-size: 14px;
}

.card-product-header:nth-child(1) {
    margin-left: 10px;
}

.hr {
    width: 100%;
    border: solid 1px #ddd;
}

.card-item {
    border: solid 1px #ddd;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-product-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    width: 100%;
}

.card-product-info {
    display: flex;
    align-items: center;
    width: 300px;
}

.card-product-info p {
    text-align: left;
    font-weight: bold;
    color: #425A8B;
    font-size: 14px;
    padding-left: 10px;
    margin-block-start: 0;
    margin-block-end: 0;
}

.card-product-info a {
    text-decoration: none;
    display: flex;
    flex-direction: column;

}

.card-product-info span {
    font-size: 11px;
    color: #f01111;
    padding-left: 10px;
    margin-top: 5px;
}

.card-price p {
    margin: 0;
    font-weight: bold;
    color: #425A8B;
    font-size: 16px;
}

.card-quantity {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card-quantity p {
    margin: 0 10px;
    font-weight: bold;
    color: #425A8B;
    font-size: 16px;
}

.product-imagee {
    width: 60px;
    height: 60px;
    margin-left: 20px;
    border: solid 1px #ddd;
    padding: 5px;
    border-radius: 3px;
}

.card-remove {
    display: flex;
    justify-content: flex-end;
    color: #425A8B;
    /* margin-right: 10px; */
    margin-top: 2px;
}

.delete-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #425A8B;
}

.delete-button:hover {
    color: #ff4d4d;
}

.order-summary {
    width: 100%;
    max-width: 450px;
    border-radius: 8px;

}

.order-summary-first {
    border: 1px solid #ddd;
    background-color: white;
    padding: 15px;
}

.order-summary-second {
    margin-top: 10px;
    border: 1px solid #ddd;
    padding: 10px;
}

.adresses-group label {
    color: #6B83B6;
    padding-left: 5px;
    margin-bottom: 3px;
    font-weight: 500;
    font-size: 14px;
}

.checkout-form-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 15px;
}

.checkout-form-group>div {
    flex: 1 1 48%;
    display: flex;
    flex-direction: column;
}

.checkout-form-group label {
    color: #6B83B6;
    padding-left: 5px;
    margin-bottom: 3px;
    font-weight: 500;
    font-size: 14px;
}

.checkout-form-group textarea {
    width: 100%;
    min-height: 40px;
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    resize: vertical;
    font-family: "DM Sans", sans-serif;
    color: #212529;
}

.checkout-form-group-address label {
    color: #6B83B6;
    padding-left: 5px;
    margin-bottom: 3px;
    font-weight: 500;
    font-size: 14px;
}

.checkout-form-group-address select {
    margin-top: 6px;
}

.ref-code {
    display: hidden;
}

.summary-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
}

.summary-item span {
    color: #425A8B;
    font-weight: bold;
}

.summary-item-total {
    display: flex;
    justify-content: space-between;

}

.summary-item-total span {
    border: none;
    color: #425A8B;
    font-weight: bold;

}

.coupon-code {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    height: 40px;
}

.coupon-code input {
    flex: 1;
    margin-right: 10px;
    padding: 5px;
    max-width: 250px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.total {
    font-weight: bold;
    font-size: 18px;
}

.checkout-form {
    margin-top: 10px;

}

.checkout-form .checkout-form-group .tckimlik input {
    max-width: 400px;
    width: 100%;
}

.checkout-form input {
    width: 100%;
    max-width: 180px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    font-family: "DM Sans", sans-serif;
    color: #212529;
}

.checkout-form select {
    width: 100%;
    padding: 10px;
    border: 1px solid #dddddd;
    border-radius: 4px;
    margin-bottom: 15px;
    font-size: 16px;
    box-sizing: border-box;
}

.address-select {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 12px;
    width: 100%;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 16px;
    color: #425A8B;
    cursor: pointer;
    transition: all 0.3s ease;
}

.address-select:hover {
    border-color: #425A8B;
}

.address-select:focus {
    outline: none;
    border-color: #425A8B;
    box-shadow: 0 0 0 2px rgba(66, 90, 139, 0.3);
}

.checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.checkbox-wrapper input[type="checkbox"] {
    margin-right: 5px;
    vertical-align: middle;
    width: auto;
}

.checkbox-wrapper label {
    font-size: 14px;
    color: #333;
    display: block;

}

.checkbox-wrapper label a {
    color: #0BA9ED;
    text-decoration: none;
    margin-left: 5px;

}

.checkbox-wrapper label a:hover {
    text-decoration: underline;
}

.buttonapply {
    width: 120px;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.buttonapply:hover {
    background-color: #0056b3;
}

.button-apply {
    width: 430px;
    padding: 10px;
    background-color: #425A8B;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.button-apply:hover {
    background-color: #333;
}

.Modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 1000px;
    width: 100%;
    height: auto;
    margin: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
}

.Modal p {
    line-height: 1.5;
    margin-bottom: 15px;
}

@media (max-width: 768px) {
    .card-container {
        padding: 10px;
    }

    .card {
        max-width: 100%;
        padding: 15px;
    }

    .card-info {
        flex-direction: column;
    }

    .card-title {
        align-items: center;
    }

    .card-title span {
        font-size: 18px;
    }

    .card-title img {
        width: 30px;
        height: 30px;
    }

    .card-title button {
        font-size: 10px;
    }

    .product-imagee {
        max-width: 150px;
    }

    .card h2 {
        font-size: 1rem;
    }

    .card p {
        font-size: 0.9rem;
    }

    .card-price {
        font-size: 1rem;
    }

    .card button {
        font-size: 0.9rem;
        padding: 12px 14px;
    }

    .card-remove {
        padding-right: 0;
    }

    .checkout-page {
        flex-direction: column;
        padding: 10px;
        gap: 0;
    }

    .card-product {
        margin-right: 0;
    }

    .card-product-header {
        padding-left: 0;
    }

    .card-product-header span {
        font-size: 12px;
    }

    .card-product-info {
        width: 100%;
        flex-direction: row;
        padding: 5px;
    }

    .card-product-info p {
        font-size: 12px;
    }

    .checkout-form input {
        width: initial;
        max-width: initial;
    }

    .checkout-form .checkout-form-group .tckimlik input {
        width: initial;
        max-width: initial;
    }

    .card-product-info span {
        font-size: 12px;
    }

    .product-imagee {
        width: 50px;
        height: 50px;
        margin-left: 0;
    }

    .card-quantity p,
    .card-price p {
        font-size: 14px;
    }

    .order-summary {
        max-width: 100%;
        margin-top: 10px;
    }

    .coupon-code {
        flex-direction: row;
        align-items: stretch;
    }

    .coupon-code input {
        width: 100%;
        margin-bottom: 10px;
        height: 30px;
    }

    .buttonapply {
        height: 40px;
        width: 100%;
        max-width: 200px;
    }

    .button-apply {
        width: 100%;
    }

    .checkout-form-group>div {
        flex: 1 1 100%;
    }

    .Modal {
        max-width: 90%;
        padding: 15px;
    }

    .Overlay {
        padding: 10px;
    }

    .summary-item,
    .summary-item-total {
        font-size: 14px;
    }

    .checkbox-wrapper label {
        font-size: 13px;
    }
}

@media (max-width: 1024px) {
    .card-container {
        padding: 0 20px;
    }

    .card {
        width: 100%;
        padding: 15px;
    }

    .card p {
        font-size: 22px;
        padding-left: 0;
        text-align: center;
    }

    .checkout-page {
        padding: 20px;
    }

    .order-summary {
        max-width: 100%;
    }

    .coupon-code input {
        max-width: 100%;
    }

    .checkout-form .checkout-form-group .tckimlik input {
        max-width: initial;
        width: initial;
    }

    .checkout-form select {
        max-width: initial;
    }

    .checkout-form input {
        max-width: initial;
        width: initial;
    }

    .buttonapply,
    .button-apply {
        width: 100%;
    }

    .checkout-form-group>div {
        flex: 1 1 100%;
    }

    .Modal {
        max-width: 90%;
        padding: 15px;
    }
}

@media (max-width: 425px) {
    .card-container {
        padding: 0 10px;
    }

    .card {
        padding: 10px;
    }

    .card p {
        font-size: 18px;
        padding: 0;
    }

    .card button {
        font-size: 14px;
        padding: 8px;
    }

    .card-product-content {
        gap: 0;
    }

    .checkout-page {
        padding: 0px;
    }

    .product-imagee {
        width: 45px;
        height: 45px;
        margin: 0;
    }

    .coupon-code {
        flex-direction: row;
        align-items: stretch;
    }

    .coupon-code input {
        width: 100%;
        margin-bottom: 10px;
        height: 30px;
    }

    .buttonapply {
        height: 40px;
        width: 100%;
        max-width: 150px;
    }

    .coupon-code input {
        max-width: 100%;
    }

    .summary-item,
    .summary-item-total {
        font-size: 14px;
    }

    .checkbox-wrapper label {
        font-size: 12px;
    }

    .Modal {
        max-width: 100%;
    }
}

/* Boş sepet için stiller */
.empty-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 60px 30px;
    margin: 40px auto;
    max-width: 600px;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
    transition: transform 0.3s ease;
}

.empty-cart-icon {
    margin-bottom: 25px;
    animation: float 3s ease-in-out infinite;
}

@keyframes float {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0px);
    }
}

.empty-cart h2 {
    color: #425A8B;
    font-size: 28px;
    margin-bottom: 15px;
    font-weight: 600;
}

.empty-cart p {
    margin: 5px 0;
    font-size: 18px;
    color: #555;
}

.empty-cart-message {
    color: #777;
    font-size: 16px;
    margin-bottom: 30px;
}

.empty-cart .shop-now-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #425A8B;
    color: white;
    padding: 14px 30px;
    border-radius: 30px;
    text-decoration: none;
    font-weight: bold;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(66, 90, 139, 0.3);
    font-size: 16px;
}

.empty-cart .shop-now-button:hover {
    background-color: #344a77;
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(66, 90, 139, 0.4);
}

.empty-cart .shop-now-button:active {
    transform: translateY(-1px);
}

/* Responsive tasarım için boş sepet stilleri */
@media (max-width: 768px) {
    .empty-cart {
        padding: 40px 20px;
        margin: 20px auto;
        max-width: 90%;
    }

    .empty-cart h2 {
        font-size: 24px;
    }

    .empty-cart p {
        font-size: 16px;
    }

    .empty-cart-message {
        font-size: 14px;
        margin-bottom: 25px;
    }

    .empty-cart .shop-now-button {
        padding: 12px 25px;
        font-size: 15px;
    }
}

@media (max-width: 425px) {
    .empty-cart {
        padding: 30px 15px;
        margin: 15px auto;
    }

    .empty-cart-icon svg {
        width: 60px;
        height: 60px;
    }

    .empty-cart h2 {
        font-size: 22px;
        margin-bottom: 10px;
    }

    .empty-cart p {
        font-size: 15px;
    }

    .empty-cart-message {
        font-size: 13px;
        margin-bottom: 20px;
    }

    .empty-cart .shop-now-button {
        padding: 10px 20px;
        font-size: 14px;
        width: 80%;
    }
}

/* Sepet Paylaşma Butonu */
.share-cart-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    margin-left: auto;
    transition: background-color 0.3s;
    z-index: 10;
}

.share-cart-button:hover {
    background-color: #357ac1;
}

/* Sepet Paylaşma Modal */
.share-modal {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px;
    padding: 24px;
    width: 90%;
    max-width: 500px;
    outline: none;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.share-modal-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.share-modal-content h2 {
    font-size: 22px;
    margin: 0;
    color: #333;
    text-align: center;
}

.share-url-container {
    display: flex;
    width: 100%;
    margin: 8px 0;
}

.share-url-input {
    flex: 1;
    padding: 10px 12px;
    border: 1px solid #ddd;
    border-radius: 4px 0 0 4px;
    font-size: 14px;
    color: #333;
}

.copy-button {
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 0 4px 4px 0;
    padding: 0 16px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.copy-button:hover {
    background-color: #357ac1;
}

.share-code-info {
    background-color: #f5f5f5;
    padding: 12px;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
}

.share-note {
    font-size: 14px;
    color: #666;
    line-height: 1.5;
}

.share-modal-actions {
    display: flex;
    justify-content: center;
    margin-top: 8px;
}

.close-share-modal {
    background-color: #f5f5f5;
    color: #333;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.close-share-modal:hover {
    background-color: #0d5aa7;
}

/* Hizmet Ekle Butonu Stilleri */
.add-service-button {
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    margin-right: 10px;
    font-size: 12px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.add-service-button:hover {
    background-color: #3d7eca;
}

/* Hizmet Modal Stilleri */
.service-modal {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px;
    max-width: 600px;
    width: 95%;
    max-height: 90vh;
    overflow-y: auto;
    padding: 0;
    outline: none;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.service-modal-content {
    padding: 25px;
}

.service-modal-content h2 {
    color: #425A8B;
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 22px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
}

.product-title {
    font-weight: bold;
    color: #555;
    margin-bottom: 20px;
    font-size: 16px;
}

.service-options {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.service-option {
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 15px;
}

.service-option h3 {
    color: #425A8B;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 16px;
}

.service-option select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
}

.service-option textarea {
    width: 100%;
    min-height: 80px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    resize: vertical;
    font-family: inherit;
}

.service-modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    gap: 10px;
}

.service-save-button,
.service-cancel-button {
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s;
    border: none;
}

.service-save-button {
    background-color: #425A8B;
    color: white;
}

.service-save-button:hover {
    background-color: #2d4271;
}

.service-cancel-button {
    background-color: #f5f5f5;
    color: #333;
    border: 1px solid #ddd;
}

.service-cancel-button:hover {
    background-color: #e9e9e9;
}

/* Mobil Görünüm */
@media (max-width: 768px) {
    .service-modal {
        width: 95%;
        max-height: 80vh;
    }

    .service-modal-content {
        padding: 15px;
    }

    .service-option {
        padding: 10px;
    }

    .add-service-button {
        padding: 6px;
    }
}

@media (max-width: 425px) {
    .add-service-button {
        padding: 4px;
    }
}

.payment-method-options {
    margin: 15px 0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
}

.payment-method-options h3 {
    margin-top: 0;
    color: #425A8B;
    font-size: 16px;
    margin-bottom: 10px;
}

.payment-radio-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.payment-radio {
    display: flex;
    align-items: center;
    gap: 8px;
}

.payment-radio input[type="radio"] {
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.payment-radio label {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: #333;
}

.eft-warning {
    margin-top: 10px;
    padding: 8px;
    background-color: #fff8f8;
    border: 1px solid #ffcdd2;
    border-radius: 4px;
}

.eft-warning p {
    margin: 0;
    color: #f44336;
    font-size: 14px;
    font-weight: 500;
}

.siparis-adres-baslik h3 {
    color: #425A8B;
    margin: 0;
    padding: 4px;
}

.no-address-message {
    background-color: #f8f9fa;
    border: 1px dashed #ddd;
    border-radius: 5px;
    padding: 15px;
    margin: 10px 0px 20px 0px;
    text-align: center;
}

.no-address-message p {
    color: #666;
    margin-bottom: 10px;
    font-size: 14px;
}

.add-address-link {
    color: #425A8B;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    transition: all 0.3s ease;
    display: inline-block;
    margin-top: 5px;
}

.add-address-link:hover {
    color: #2a3b5d;
    text-decoration: underline;
}

