.searchbar {
  display: flex;
  align-items: center;
  border-radius: 4px;
  justify-content: center;
  position: relative;
}

.searchbar form {
  width: 100%;
  max-width: 500px;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px; 
  
}

.search-input {
  flex-grow: 1;
  padding: 12px;
  border: none;
  outline: none;
  font-size: 14px;
  width: 100%;
  border-radius: 10px;
}

.search-button {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #666;
  border-radius: 50px;
  transition: color 0.3s ease;
}

.search-button:hover {
  background: #f3f3f3;
}

.search-results {
  position: absolute;
  top: 100%;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
  max-height: 500px;
  overflow-y: auto;
  width: 100%;
  max-width: 500px; 
  
}

.search-results-product {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}
.search-results-product p {
  margin: 0;
  font-size: 14px;
  color: #425A8B;
  font-weight: bold;
}
.search-results-product a{
  display: flex;
  align-items: center;
  text-decoration: none;
  
}
.no-results {
  padding: 10px;
  color: #999;
}
.search-results-loading{
  padding: 10px;
}
@media (max-width: 1024px) {
 
  .searchbar {
    margin: 10px; 
  }

  .searchbar form {
    width: 100%; 
   
  }
  .searchbar input {
    padding: 10px; 
    font-size: 16px; 
  }

  .search-results {
    max-width: 100%; 
  }

  .search-results-product p {
    font-size: 16px; 
  }

  .no-results {
    font-size: 14px; 
  }
}


@media (max-width: 425px) {
  .searchbar {
    margin: 5px; 
  }
  
  .searchbar input {
    padding: 6px; 
    font-size: 14px; 
  }

  .search-results {
    max-width: 100%; 
  }

  .search-results-product p {
    font-size: 14px; 
  }

  .no-results {
    font-size: 12px; 
  }
}


@media (max-width: 768px) {
  .searchbar {
    max-width: 100%;
    margin: 10px; 
  }

  .searchbar input {
    padding-left: 10px; 
    padding-right: 5px; 
    font-size: 14px; 
  }
  .search-results {
    max-height: 200px; 
  }

  .search-results-product {
    padding: 8px; 
  }

  .search-results-product p {
    font-size: 12px; 
  }
  .no-results {
    padding: 8px; 
    font-size: 12px; 
  }
}



