.store-container {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-top: 20px;
    width: 100%;

}

.store-list {
    margin-left: 10px;
    margin-top: 0;
}

.categories-item {
    display: flex;
    justify-content: space-between;
}

.categories-item span {
    font-size: 12px;
    cursor: pointer;
    margin-top: 12px;
}

.magaza-photo {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: end;
    align-items: center;
    overflow: hidden;
    margin-top: 20px;
}

.magaza-photo-img {
    width: 80%;
    height: 180px;
    position: relative;
    overflow: hidden;
}

.magaza-photo-img img {
    width: 100%;
    height: 100%;
    object-position: center;
    display: block;
    transition: transform 0.3s ease;
}

.store-product {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;


}

.store-product-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    max-width: 100%;
    margin: 0;

}

.store-product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.store-product-card:hover .favorite-iconn {
    opacity: 1;
    visibility: visible;
}

.store-img-container {
    aspect-ratio: 4/5;
    position: relative;
    width: 100%;
    overflow: hidden;

}

.store-img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;

}

.store-product-card:hover img {
    transform: scale(1.05);
}

.store-responsive-info {
    margin-left: 10px;

}

.favorite-iconn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 10;
    visibility: visible !important;
    opacity: 1 !important;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    padding: 8px;
    display: block !important;

}

.store-pricee {
    margin-top: 5px;
    display: flex;
    align-items: left;
    margin-bottom: 10px;
    margin-block-start: 5px;
    margin-block-end: 5px;
}

.store-originall-price {

    color: #333333;
    margin-right: 8px;
    font-size: 16px;
    font-weight: bold;
    margin-block-start: 5px;
    margin-block-end: 5px;
}

.store-discountedd-price {
    color: #e63946;
    font-weight: bold;
    font-size: 16px;
    margin-block-start: 5px;
    margin-block-end: 5px;
}

.store-originall-price.text-decoration-line-through {
    text-decoration: line-through;
}

.store-responsive-name {
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #425A8B;
    margin-block-start: 10px;
    margin-block-end: 0;
}


.categoriesfilter {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    padding: 5px;
    background-color: #f5f5f5;
    border-radius: 3px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 240px;
    margin-left: 5px;
}

.categoriesfilter label {
    display: block;
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: bold;
    padding-left: 6px;

}

.categoriesfilter .price-inputs {
    display: flex;
    gap: 10px;
}

.categoriesfilter input {
    width: 100%;
    max-width: 80px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;

}

.categoriesfilter .search-icon {
    cursor: pointer;
    font-size: 20px;
    color: #425A8B;
    transition: color 0.3s ease;
    margin-top: 5px;

}

@media (max-width: 768px) and (min-width: 425px) {
    .store-container {
        flex-direction: column;
        align-items: center;
        padding: 0;
        width: 100%;
    }

    .categories-item span {
        font-size: 10px;
    }

    .magaza-photo {
        margin-top: 0;
        justify-content: center;
    }

    .store-list {
        width: 100%;
    }

    .store-product {

        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
    }

    .store-product-card {
        margin: 0;
        width: 100%;

    }

    .price-inputs {
        flex-direction: row;
        gap: 5px;
    }

    .price-inputs input {
        width: 40%;
    }

    .store-img-container {
        height: 200px;
    }

    .search-icon {
        font-size: 18px;
        margin-left: 5px;
    }
}

@media (max-width: 768px) {
    .magaza-photo {
        justify-content: center;
    }

    .magaza-photo-img {
        height: 200px;
        width: 100%;
    }
    .store-list {
        display: flex;
        justify-content: space-between;
        margin: 0;
        width: 100%;
    }
    .category-container {
        top: 450px;
    }
}

@media (max-width: 425px) {
    .store-container {
        display: flex;
        flex-wrap: wrap;
    }

    .store-product {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }

    .store-product-card {

        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .store-img-container img {
        height: 100%;
    }

    .price-inputs {

        gap: 5px;
        width: 100%;
    }

    .price-inputs input {
        width: 100%;
        padding: 4px;
    }

    .search-icon {
        font-size: 18px;
        align-self: flex-end;
        margin-top: 10px;
    }

    .store-responsive-info {
        text-align: center;
    }

    .store-responsive-name {
        font-size: 14px;
        margin-block-start: 10px;
        margin-block-end: 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .magaza-photo {
        width: 100%;

    }

    .magaza-photo-img {
        height: 150px;
    }

    .store-pricee {
        justify-content: center;
        margin-bottom: 10px;
        font-size: 16px;
    }

    .store-originall-price {
        font-size: 16px;

    }

    .store-discountedd-price {
        font-size: 16px;
    }

    .sidebar-toggle {
        font-size: 14px !important;
    }
}