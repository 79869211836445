.inst-page-wrapper {
    display: flex;
    gap: 40px;
    padding: 40px;
    box-sizing: border-box;
    flex-wrap: wrap;
  }
  
  .inst-categories-section {
    width: 100%;
    max-width: 280px;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    overflow-y: auto;
    height: 100%;
    max-height: 550px;

  }
  
  .inst-categories-section h2 {
    font-size: 28px;
    margin-bottom: 20px;
    color: #333;
    font-weight: 500;
    text-align: center;
  }
  
  .inst-categories-section ul {
    list-style-type: none;
    padding: 0;
  }
  
  .inst-category-item {
    padding: 12px;
    margin-bottom: 12px;
    border-radius: 5px;
    font-size:16px;
    color: #444;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    text-align: center;
  }
  
  .inst-category-item:hover {
    background-color: transparent;
   
  }
  
  
  .inst-content-section {
    flex: 1;
    background-color: #fff;
    border-radius: 8px;
    padding: 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  }
  
  .inst-content-section h2 {
    font-size:16px;
    color: #333;
    margin-bottom: 20px;
    font-weight: 500;
  }
  
  .inst-content-section h3 {
    font-size: 22px;
    margin-bottom: 15px;
    color: #444;
  }
  
  .inst-content-section p {
    font-size: 14px;
    color: #555;
    line-height: 1.7;
    text-align: justify;
    margin-bottom: 20px;
  }
  
 
  @media (max-width: 768px) {
    .inst-page-wrapper {
      flex-direction: column;
      align-items: center;
      padding: 10px;
      gap: 10px;
    }
  
    .inst-categories-section {
      max-width: 100%;
      padding: 15px;
    }
  
    .inst-categories-section h2 {
      font-size: 22px;
      margin-bottom: 15px;
    }
  
    .inst-category-item {
      padding: 10px;
      font-size: 14px;
      margin-bottom: 10px;
    }
  
    .inst-content-section {
      width: 100%;
      padding: 20px;
    }
  
    .inst-content-section h3 {
      font-size: 18px;
      margin-bottom: 10px;
    }
  
    .inst-content-section p {
      font-size: 13px;
      line-height: 1.6;
    }
  }
  
  
  @media (max-width: 425px) {
    .inst-categories-section {
      width: 100%;
      max-width: 300px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05); 
    }

    .inst-categories-section, .inst-content-section {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05); 
    }
    .inst-categories-section h2 {
      font-size: 20px;
    }
  
    .inst-category-item {
      padding: 8px;
      font-size: 13px;
    }
    .inst-content-section{
      padding: 5px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05); 
    }
    .inst-content-section h3 {
      font-size: 16px;
    }
  
    .inst-content-section p {
      font-size: 12px;
    }
  
    .inst-page-wrapper {
      padding: 5px;
      gap: 5px;
    }
  }