.empty-cart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    text-align: center;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 2rem auto;
    max-width: 600px;
  }
  
  .empty-cart-icon {
    margin-bottom: 1rem;
  }
  
  .empty-cart h2 {
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .empty-cart p {
    color: #666;
    margin-bottom: 1rem;
  }
  
  .empty-cart-message {
    font-size: 0.9rem;
    color: #888;
    margin-bottom: 2rem;
  }
  
  .shop-now-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #007bff;
    color: white;
    padding: 0.8rem 1.5rem;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 500;
    transition: background-color 0.2s;
  }
  
  .shop-now-button:hover {
    background-color: #0056b3;
  } 