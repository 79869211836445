
.content {
  padding-top: 160px; 
}
@media  (max-width: 768px) {
    .content {
        padding-top: 170px; 
    }
  }
  @media  (max-width: 1024px) {
    .content {
        padding-top: 180px; 
    }
  }
 
  
  