.category-container {
  border: 1px solid #ddd;
  width: 100%;
  max-width: 250px; 
  position: absolute; 
  background: #fff; 
  transition: transform 0.3s ease; 
  display: none; 
  margin-left: 5px;
  z-index: 20;
}

.category-container.open {
  display: block; 
}

.titlee {
  font-weight: bold;
  text-decoration: none;
  color: #425A8B;
  font-size: 16px;
  padding-left: 20px;
  display: block;
  transition: color 0.3s ease;
  border-bottom: 1px solid #ddd;
}

.category-listt {
  list-style: none;
  margin: 0;
  padding: 0;
}

.category-listt ul {
  list-style: none;
  padding: 10px;
  margin: 0;
}

.category-listt ul ul {
  margin-left: 20px;
}

.category-listt li {
  margin: 5px 0;
}

.category-listt a {
  font-weight: bold;
  text-decoration: none;
  color: #425A8B;
  font-size: 14px;
  padding: 9px;
  display: block;
  transition: color 0.3s ease;
}

.category-listt a:hover {
  color: orange;
}


.sidebar-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
  color: #425A8B;
  padding: 10px;
 
 
}
.sidebar-toggle svg {
  margin-right: 8px;
}

@media (min-width: 768px) {
  .sidebar-toggle {
      display: none;
  }

  .category-container {
      display: block; 
      position: relative; 
      z-index: 20;
  }
 
}
@media (max-width: 768px) {
  .category-container {
    border: 1px solid #ddd;
    width: 100%;
    max-width: 250px; 
    position: absolute; 
    background: #fff; 
    transition: transform 0.3s ease; 
    display: none; 
    margin-left: 5px;
  }

  .category-container.open {
    display: block;
  }

  .titlee {
    font-weight: bold;
    text-decoration: none;
    color: #425A8B;
    font-size: 14px; 
    padding-left: 15px; 
    display: block;
    transition: color 0.3s ease;
    border-bottom: 1px solid #ddd;
  }

  .category-listt {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .category-listt ul {
    list-style: none;
    padding: 8px; 
    margin: 0;
  }

  .category-listt ul ul {
    margin-left: 15px; 
  }

  .category-listt li {
    margin: 4px 0; 
  }

  .category-listt a {
    font-weight: bold;
    text-decoration: none;
    color: #425A8B;
    font-size: 12px; 
    padding: 7px; 
    display: block;
    transition: color 0.3s ease;
  }

  .category-listt a:hover {
    color: orange;
  }

  .sidebar-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 20px; 
    color: #425A8B;
    padding: 8px; 
  }

  .sidebar-toggle svg {
    margin-right: 6px; 
  }
}

