.scroll-to-top-button {
    position: fixed;
    bottom: 30px;
    left: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #425A8B;
    color: white;
    border: none;
    cursor: pointer;
    z-index: 999;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    opacity: 0.9;
    transition: all 0.3s ease;
  }
  
  .scroll-to-top-button:hover {
    opacity: 1;
    background-color: #344c7c;
    transform: translateY(-5px);
  }
  
  .scroll-to-top-button i {
    font-size: 20px;
    margin-bottom: 2px;
  }
  
  .scroll-to-top-button span {
    font-size: 12px;
    font-weight: bold;
  }
  
  @media (max-width: 768px) {
    .scroll-to-top-button {
      width: 30px;
      height: 30px;
      bottom: 20px;
      left: 20px;
    }
    
    .scroll-to-top-button i {
      font-size: 18px;
    }
    
    .scroll-to-top-button span {
      font-size: 10px;
    }
  } 