.card-item {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    padding: 1rem;
}

.card-product-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.5rem;
}

.card-product-header span {
    color: #666666;
    font-size: 0.9rem;
}

.card-product-header a span {
    text-decoration: none;
    color: #007bff;
}

.hr {
    border: none;
    border-top: 1px solid #eee;
    margin: 0.5rem 0;
}

.card-product-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.card-product-info {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex: 1;
}

.card-product-info img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
}

.card-product-info a {
    text-decoration: none;
    color: #333;
}

.card-product-info p {
    margin: 0;
    font-weight: 500;
}

.card-product-info span {
    font-size: 0.6rem;
    color: red;
}

.card-quantity {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.card-price {
    font-weight: 500;
    color: #333;
    min-width: 100px;
    text-align: right;
}

.card-remove {
    display: flex;
    align-items: center;
}

.delete-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    transition: color 0.2s;
}

.delete-button:hover {
    color: #c82333;
}

@media (max-width:768px) {
    .card-item {
        padding: 6px 0px;
    }
    .card-product-info {
        width: 100%;
        flex-direction: row;
        padding: 5px;
        gap: 0px;
    }

    .card-product-info img {
        width: 45px;
        height: 45px;
    }

    .card-product-header {
        padding-left: 0;
    }

    .card-product-header span {
        font-size: 12px;
    }

    .card-quantity p {
        margin: 0;
    }

    .delete-button {
        margin-right: 10px;
    }
}

@media (max-width: 425px) {
    .card-product-content {
        gap: 0;
    }
}