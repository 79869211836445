.sort-dropdown {
    display: flex;
    justify-content: flex-end; 
}
.sort-dropdown label {
    margin-right: 10px; 
}

.sort-dropdown select {
    padding: 5px; 
}
.categories-container{
    display: flex;
    align-items: flex-start;   
    gap: 20px;
}
.categories-list{
    margin-left: 15px;
    margin-top: 20px;
}
.categories-filter {
    display: flex;
    flex-direction:column;
    margin-top: 20px;
    padding: 5px;
    background-color: #f5f5f5;
    border-radius: 3px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    width: 250px;
}

.categories-filter label {
    display: block;
    font-size: 16px;
    margin-bottom: 8px;
    font-weight: bold;
    padding-left: 6px;
    
}

.categories-filter .price-inputs {
    display: flex;
    gap: 10px;
}

.categories-filter input {
    width: 100%;
    max-width: 80px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    
}

.categories-filter .search-icon {
    cursor: pointer;
    font-size: 20px;
    color: #425A8B;
    transition: color 0.3s ease;
    margin-top: 5px;
    
}

.categories-product{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-top: 20px;
    
}
.categories-product-card{
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: left; 
    max-width: 100%;
    white-space: nowrap; 
    text-overflow: ellipsis; 
    overflow: hidden;
    margin: 0;
    position: relative;
}
.categories-product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  .categories-product-card:hover .favorite-icon {
    opacity: 1; 
    visibility: visible; 
}
.categories-img-container{
    aspect-ratio: 4/5;
    width: 100%;
    overflow: hidden;
    position: relative; 

}
.favorite-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 10;
    visibility: visible!important;  
    opacity: 1 !important;
    transition: none;
    display: block !important; 
    transition: opacity 0.3s ease, visibility 0.3s ease;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    padding: 8px;
}
.categories-img-container img{
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;
    object-fit: cover;
}
.categories-product-card:hover img{
    transform: scale(1.05);
}
.categories-responsive-info {
    margin-left: 10px;
    overflow: hidden; 
    
  }
.categories-responsive-name {
    font-size: 14px;
    margin-block-start: 10px;
    margin-block-end: 5px;
    white-space: nowrap; 
    text-overflow: ellipsis; 
    overflow: hidden;
    color: #425A8B;
  
}

@media (max-width: 768px) and (min-width: 425px) {
    .sort-dropdown {
        flex-direction: column; 
        align-items: flex-start; 
        padding-left: 15px;
        
    }
    
    .categories-container {
        flex-direction: column; 
        width: 100%;    
    }

    .categories-list {
        width: 100%; 
       
    }

    .categories-filter {
        width: 40%; 
    }

    .categories-product {
       grid-template-columns: repeat(4, 1fr); 
      gap: 5px; 
    }
    .categories-product-card{
        width: 100%;
        margin: 0;
    }
    .categories-img-container {
        height: 200px; 
    }

    .categories-responsive-name {
        font-size: 14px; 
    }
    .originall-price{
        font-size: 16px;
    }
}
@media (max-width: 425px) {
    .sort-dropdown {
        flex-direction: column; 
        align-items: flex-start; 
        padding-left: 0px;
    }
    .categories-container {
       display:flex;
		flex-wrap:wrap;
            
    }
    .categories-filter {
       max-width: 250px;
       width: 100%;
    }
    .categories-list {
        width: 100%; 
        margin-left: 0;
         
    }
    .categories-product {
        grid-template-columns: repeat(2, 1fr); 
        gap: 10px;
       
    }
    .categories-product-card {
        
        width: 100%;
        white-space: nowrap; 
        text-overflow: ellipsis; 
        overflow: hidden;
        
    }
    .categories-img-container img {
        height:100% !important; 
    }
    .categories-responsive-info{
        text-align: center;
    }
    .categories-responsive-name{
        font-size: 14px;
        margin-block-start: 10px;
        margin-block-end: 5px;
        
    }
}
