.product-detail{
  display: flex;
  flex-direction: column;
  max-width: 100%;
}
.product-detail-container {
  display: flex;
  padding: 20px;
  gap: 20px;
  
}

.product-info {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.product-info h1 {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: bold;
  color:  #425A8B;
  
}

.stok-bilgi{
  color: rgb(230, 3, 3) !important;
  font-weight: bold !important;
}
.explanation{
  width: 70%;
  margin-top: 20px;
  margin-bottom: 10px;
}
.explanation p{
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #6B83B6;
  
}
.product-info p {
  margin: 0;
  line-height: 1.6;
  color:  #425A8B;
 
}
.product-actions {
  display: flex;
  align-items: center; 
  gap: 10px; 
}
.product-actions p{
  font-size: 14px;
}
.product-actions a{
  text-decoration: none;
  color: #425A8B;
}
.favorite {
  display: flex;
  align-items: center;
  cursor: pointer;
  animation: pulse 2s infinite;
}
.favorite-icon {
  transition: color 0.3s ease; 
}
.favorite:hover .favorite-icon {
  transform: scale(1.1);
}

.favorite:hover .favorite-icon[color="gray"] {
  color: red 
}
.purchase-options {
  display: flex;
  gap: 10px;  
  margin-top: 20px;
 
}
.detail-prices {
  display: flex;
  align-items: left;
  font-size: 32px;
}
.detail-originall-price {
  
  color: #333333;
  margin-right: 16px;
  font-size: 32px;
  font-weight: bold;
  margin-block-start: 0;
  margin-block-end: 0;
}
.detail-originall-price.text-decoration-line-through {
  text-decoration: line-through;
}
.taksit-secenegi {
  color: #ffffff; 
  font-weight: bold;
  background: linear-gradient(45deg, #ff8a00, #e52e71); 
  padding: 6px 12px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  font-size: 0.9em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
  gap: 6px; 
}

.detail-discountedd-price {
  font-size: 32px;
  color: #e63946;
  margin-block-start: 0;
  margin-block-end: 0;
  
}
.purchase-options button {
  padding: 10px 20px;
  margin: 0;
  border-radius: 3px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  text-align: center;
  width: 200px;
  
  
}
.card-button{
  background-color: #FD9636;
  color: #fff;
  border: 1px solid #f3f4ff;
  
  
}
.card-button:hover {
  background-color: #ec882b;
  color: white;
}
.card-button-buy{
  background-color: #536b9d;
  color: white;
  border: 1px solid #8C9EC5;
}
.card-button-buy:hover {
  background-color: #2e4676;
}
.whatsapp{
  background-color: #0eaa49;
  color: white;
  border: none;

}
.whatsapp:hover{
  background-color: #0b913e;
}
.seller-info {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 45%;
}
.seller-info img{
  width: 100px;
  height: auto;
  margin-right: 15px; 
  margin-left: 15px;
  margin-top: 10px
}

.seller-info-store{
  display: flex;
  flex-direction: column; 
  justify-content: center;
}
.seller-info p {
  color: black;
  text-align: left; 
  margin: 0; 
}
.seller-info a{
  text-decoration: none;
  color: #425A8B;
  font-weight: bold;
  margin-top: 5px;

}
.product-info hr{
  border-color:#DDE4F0
}
.payment-options  {
    color:#fc2119;
    font-weight: bold;
}
.ring-size {
  margin-top: 5px;
  display: block;
}

.ring-size p {
  font-size: 16px;
  font-weight: bold;
}

.ring-size select {
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 10px;
  width: 100px;
  cursor: pointer;
  display: block;
}

.ring-size select option {
  padding: 8px;
}

.store{
  background-color: #FD9636;
  color: white;
  cursor: pointer;
  border: none;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  float: right;
  width: 150px;
}
.store:hover{
  background-color: #FD9636;
}
strong{
  color: #425A8B;
  font-weight: bold;
  
}
.tabs-container {
  width: 100%;
  margin-top: 20px;
}

.tabs-header {
  display: flex;
  border-bottom: 1px solid #e0e0e0;
}

.tabs-header button {
  display: block;
  padding: 10px 20px;
  background: none;
  border: 1px solid #ddd;
  cursor: pointer;
  font-size: 16px;
  color: #425A8B;
  outline: none;
  transition: all 0.3s ease;
  font-family: "DM Sans", sans-serif;
  border-radius: 5px;
  margin-right: 10px;
  margin-bottom: 15px;
}
.tabs-header button:hover {
  background: none; 
  color: #425A8B; 
  border-color: #ddd; 
}
.tabs-header button.active {
  background-color: transparent;
  font-weight: bold;
}

.tabs-content {
  padding: 20px;
  background-color: transparent;
  border: 1px solid #e0e0e0;
  border-top: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.whatsapp-info{
  color: #0eaa49;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  border: none;
  padding: 4px;
}

.whatsapp-info:hover{
  color: #0b913e;
  background-color: lightgray;
}

.tab-pane h4 {
  font-size: 18px;
  margin-bottom: 10px;
 
  color: #425A8B;
}

.tab-pane p {
  font-size: 14px;
  line-height: 1.5;
  
}

.recommendation-continer{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}
.recommendation-container h3{
  color: #425A8B;
  font-size: 24px;
  font-weight: bold;
  
}
.recommendation {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
  gap: 20px;
  margin-top: 20px;
}

.recommendation-card {
    
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left;
  transition: transform 0.3s ease;
  width: 100%;
  margin: 0;
  white-space: nowrap; 
  text-overflow: ellipsis; 
  position: relative;
  overflow: hidden;
}

.recommendation-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}
.recommendation-img-container{
  width: 100%;
  aspect-ratio: 4/5;
  overflow: hidden;
  position: relative; 
}
.recommendation-card img {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease;
  object-fit: cover;
}
.recommendation-card:hover img{
  transform: scale(1.05);
}
.recommendation-card-responsive__info {

  margin-left: 10px;
}

.recommendation-card-responsive-name{
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
  font-weight: bold;
  margin-block-start: 5px;
  margin-block-end: 0;
}
.recommendation-card-responsive-name a {
  text-decoration: none;
  color: #425A8B;
  transition: color 0.3s ease;

  font-size: 14px;
}
.recommendation-card-responsive-name a:hover {
  color: #425A8B
}
.favorite-iconn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 10;
  visibility: visible!important;  
  opacity: 1 !important;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  display: block !important; 
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  padding: 8px;
  animation: pulse 2s infinite;
}
.recommendation-card:hover .favorite-iconn {
  opacity: 1; 
  visibility: visible; 
}

@keyframes pulse {
  from 0%{
    transform: scale(1);
  }
  to 50%{
    transform: scale(1.1);
  }
  to 100%{
    transform: scale(1);
  }
}

.variants ul {
  list-style-type: none; 
  margin: 0;
  padding: 0;
}
.variants-item {
  display: flex;
  flex-direction: column;
}
.variant-title h4{
  color: #425A8B;
  margin-block-start: 0;
  margin-block-end: 5px;
}
.variants-item strong {
  
  font-weight: bold;
  font-size: 14px;
  text-align: center; 
  color: #425A8B;;
}
.variants-item span {
  color: #425A8B;; 
  font-size: 14px;
  text-align: center; 
}

@media (max-width: 768px) and (min-width: 425px) {
  .product-detail-container {
    padding: 15px;
    flex-direction: column;
  }

  .product-info h1 {
    font-size: 20px;
  }

  .product-info h2 {
    font-size: 28px;
  }

  .product-actions {
    /* flex-direction: column; */
    align-items: flex-start;
  }

  .recommendation {
    grid-template-columns: repeat(2, 1fr);
  }

  .purchase-options {
    flex-direction: column;
    width: 100%;
  }
  .purchase-options button {
    width: 100%;
  }

  .seller-info-store {
    align-items: start;
  }

  .recommendation-card {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
    font-weight: bold;
  }

  .recommendation-card-responsive-name {
    font-size: 12px;

  }

  .priceee {
    font-size: 16px;
  }

  .tabs-header button {
    font-size: 14px;
    padding: 8px 15px;
  }

  .tabs-content {
    padding: 15px;
  }

  .seller-info {
    justify-content: space-around;
    width: inherit;
    margin-top: 15px;
  }

  .payment-options table {
    width: 100%;
  }
}

@media (max-width: 425px) {
  .product-detail-container {
    padding:0;
    flex-direction: column;
    gap: 5px;
    margin: 5px;
  }
  .product-actions {
    display: flex;
    flex-direction: row;
    gap: 10px; 
    
  }
  .product-detail{
    display: flex;
    flex-direction: column;
    max-width: 100%;
    box-sizing: border-box;
  }
 
  .product-info h1 {
    font-size: 18px;
  }

  .product-info h2 {
    font-size: 24px;
  }
  .purchase-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
  .purchase-options button {
    font-size: 14px;
    font-weight: bold;
    padding: 10px;
    width: 100%;
    text-align: center;
    
    border-radius: 5px;
   
    
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .explanation{
    width: 100%;
  }
  .store {
    width: 100%;
    font-size: 12px;
    padding: 8px;
  }

  .seller-info {
   
    width: initial;
   
  }
  .recommendation {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  .recommendation-card{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
    font-weight: bold;
  }
  .recommendation-card img {
    height: 200px;
  }
  .recommendation-img-container{
    height: 200px;
  }
  .recommendation-container {
    gap: 15px;
  }

  .product-actions p {
    font-size: 12px;
  }
  .tabs-content {
    padding: 15px;
  }
  .tabs-header button {
    font-size: 12px;
    padding: 6px 10px;
  }

  .recommendation-card-responsive-name {
    font-size: 12px;
   
  }
  .detail-originall-price{
    font-size: 16px;
  }
 
}
@media (max-width: 1024px) and (min-width: 769px) {
  .product-detail-container {
    padding: 20px;
    flex-direction: row;
    gap: 20px;
  }

  .product-info h1 {
    font-size: 22px;
  }

  .product-info h2 {
    font-size: 30px;
  }

  .product-actions {
    flex-direction: row;
    gap: 15px;
    align-items: center;
  }

  .recommendation {
    grid-template-columns: repeat(3, 1fr);
  }

  .recommendation-card {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
    font-weight: bold;
  }

  .recommendation-card-responsive-name {
    font-size: 14px;
  }

  
  .purchase-options button {
    font-size: 12px;
    font-weight: normal;
    padding: 5px;
    
    max-width: 150px;
    white-space: nowrap;
  }
  .tabs-header button {
    font-size: 16px;
    padding: 10px 20px;
  }

  .tabs-content {
    padding: 20px;
  }

  .seller-info {
    width: 100%;
    max-width: 350px;
    padding: 5px;
  }

  .payment-options table {
    width: 100%;
  }

  .store {
    width: auto;
    font-size: 14px;
    padding: 10px 20px;
  }

  .recommendation-card img {
    height: 250px;
  }

  .recommendation-img-container {
    height: 250px;
  }

  .recommendation-container {
    gap: 20px;
  }

  .product-actions p {
    font-size: 14px;
  }

  .detail-originall-price {
    font-size: 18px;
  }

  .product-info hr {
    border-color: #DDE4F0;
  }

  .tabs-header button.active {
    font-weight: bold;
  }

  .recommendation-card-responsive-name a {
    font-size: 14px;
  }
}
