.forgotPassword-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    
}

.forgotPassword-title {
    font-size: 32px;
    margin-bottom: 10px;
    font-family: "DM Sans", sans-serif;
    font-weight: bold;
    color:#495e95;
}

.forgotPassword-subtitle {
    font-size: 12px;
    color: #8C9EC5;
    margin-bottom: 20px;
    font-family: "DM Sans", sans-serif;
}

.forgotPassword-form {
    display: flex;
    flex-direction: column;
}

.forgotPassword-label {
    text-align: left;
    font-size: 14px;
    margin-bottom: 5px;
}

.forgotPassword-input {
    padding: 10px;
    font-size: 16px;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid #ccc;
    
}

.forgotPassword-button {
    background-color: #495e95;
    color: white;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: "DM Sans", sans-serif;
    height: 52px;
    font-weight: bold;
}

.forgotPassword-button:hover {
    background-color: #495e95;
}

.forgotPassword-loginLink {
    margin-top: 20px;
    font-size: 14px;
    color: #8C9EC5;
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
}
.forgotPassword-loginLink a{
    font-family: "DM Sans", sans-serif;
    font-size: 12px;
    text-decoration: none;
    color: #425A8B
}