.main-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 30px;

}

.account-title {
    font-size: 25px;
    margin-bottom: 20px;
    color: #425A8B;

}

.btn {
    background-color: #425A8B;
    color: white;
    height: 45px;
    border-radius: 2px;
    border: none;
    cursor: pointer;
    margin-top: 30px;
    width: 100%;
    max-width: 500px;
}

.btn:hover {
    background-color: #425A8B;
}

.btn:disabled {
    background-color: #a0b0d1;
    cursor: not-allowed;
    opacity: 0.8;
}

.consent-label {
    font-weight: normal;
    display: flex;
    align-items: center;
    font-size: 14px !important;
    gap: 4px;

}

.consent-label a {
    color: #007bff;
    text-decoration: none;
    font-family: "DM Sans", sans-serif;
}

.consent-label a:hover {
    text-decoration: underline;
}

/* Modal stilleri */
.Modal {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 800px;
    width: 80%;
    max-height: 80vh;
    overflow-y: auto;
    outline: none;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    color: #425A8B;
}

.close-button:hover {
    color: #333;
}

.Modal h2 {
    color: #425A8B;
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}

.Modal p {
    margin-bottom: 15px;
    line-height: 1.6;
}

.account-footer p {
    font-size: 14px;
    color: #6c757d;
}

.register-check {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.register-check input[type="checkbox"] {
    margin-right: 5px;
    vertical-align: middle;
    width: auto;
}

.register-check label {
    font-size: 14px;
    color: #333;
    display: block;
}

.register-check label a {
    color: #0BA9ED;;
    text-decoration: none;
    margin-left: 5px; 
}

@media (max-width: 768px) {
    .account-title {
        font-size: 22px;
        margin-bottom: 15px;
    }

    .form-row {
        max-width: 100%;
        padding: 8px;
    }

    .btn {
        height: 40px;
    }

    .consent-label {
        font-size: 12px !important;
    }

    .Modal {
        width: 90%;
        padding: 15px;
    }
}

@media (max-width: 480px) {
    .account-title {
        font-size: 20px;
    }

    .form-row {
        padding: 6px;
    }

    .btn {
        height: 38px;
    }

    .consent-label {
        font-size: 11px !important;
    }

    .Modal {
        width: 95%;
        padding: 10px;
    }
}