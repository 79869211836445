.user-profile-container {
  color: #425A8B;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.user-profile-header {
  margin-bottom: 30px;
}

.user-profile-info {
  display: flex;
  align-items: center;
  gap: 30px;
}

.user-profile-image-container {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.user-profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-profile-image-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #425A8B;
  background-color: #e9ecef;
}

.user-image-upload-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(73, 94, 149, 0.7);
  padding: 8px 0;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.user-profile-image-container:hover .user-image-upload-overlay {
  opacity: 1;
}

.user-upload-button {
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 14px;
}

.user-profile-text h2 {
  margin-bottom: 10px;
  color: #425A8B;
  font-size: 24px;
}

.user-profile-text p {
  color: #6c757d;
  font-size: 16px;
}

.user-profile-content {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  padding: 20px;
}

.user-settings-navigation {
  display: flex;
  justify-content: space-around;
  gap: 30px;
  /* margin-bottom: 30px; */
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 10px;
}

.user-settings-navigation button {
  color: #495e95;
  font-size: 16px;
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  background-color: transparent;
  position: relative;
  transition: all 0.3s;
}

.user-settings-navigation button:hover {
  background-color: rgba(73, 94, 149, 0.1);
  border-radius: 4px;
}

.user-active-tab {
  font-weight: bold;
  color: #425A8B !important;
}

.user-active-tab::after {
  content: '';
  position: absolute;
  bottom: -11px;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #425A8B;
}

.user-settings-container,
.user-addresses-container,
.user-orders-container {
  padding: 20px 0;
}

.user-account-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 100%;
  margin-bottom: 30px;
}

.user-form-row {
  display: flex;
  /* gap: 20px; */
  width: 100%;
  flex-wrap: wrap;
}

.user-form-user {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 250px;
  margin-bottom: 15px;
}

.user-form-user label {
  font-size: 14px;
  margin-bottom: 8px;
  color: #495e95;
  display: block;
  padding-left: 3px;
  font-weight: 500;
}

.user-form-user input,
.user-form-user textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 5px;
  box-sizing: border-box;
}

.user-form-user select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  margin-top: 5px;
  background-color: #fff;
  height: 40px;
  cursor: pointer;
}

.user-form-user select:focus {
  outline: none;
  border-color: #425A8B;
  box-shadow: 0 0 0 2px rgba(66, 90, 139, 0.3);
}

.user-form-user select:disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.user-form-user input:focus,
.user-form-user textarea:focus {
  outline: none;
  border-color: #425A8B;
}

.user-form-user textarea {
  resize: vertical;
  min-height: 100px;
}

.user-update-button,
.user-edit-address-button,
.user-add-address-button {
  background-color: #495e95;
  color: white;
  padding: 12px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s;
  max-width: 300px;
  /* margin-top: 10px; */
}

.user-update-button:hover,
.user-edit-address-button:hover,
.user-add-address-button:hover {
  background-color: #3a4d84;
}

.user-section-title {
  display: flex;
  align-items: center;
  color: #425A8B;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
}

.user-edit-icon {
  margin-left: 10px;
  font-size: 16px;
  color: #6c757d;
}

.address-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.user-address-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.user-address-card {
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 20px;
  background-color: #f8f9fa;
  position: relative;
  flex: 1;
  /* min-width: 280px; */
  max-width: 400px;
}

.user-address-card h4 {
  color: #425A8B;
  margin-bottom: 10px;
  font-size: 16px;
}

.user-address-card p {
  margin-bottom: 15px;
  color: #6c757d;
  line-height: 1.4;
}

.user-address-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-top: 10px;
}

.user-edit-address-button {
  font-size: 14px;
  padding: 8px 15px;
  flex: 1;
}

.user-delete-address-button {
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 9px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.user-delete-address-button:hover {
  background-color: #bd2130;
}

.user-no-address-message {
  color: #6c757d;
  font-style: italic;
  padding: 20px;
  text-align: center;
  width: 100%;
}

/* Modal Stilleri */
.user-address-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.user-address-modal {
  background-color: white;
  border-radius: 8px;
  padding: 25px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-height: 90vh;
  overflow-y: auto;
}

.user-address-modal h3 {
  color: #425A8B;
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
}

.user-address-modal-form {
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
}

.user-address-modal-actions {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-top: 10px;
}

.user-cancel-button {
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px;
  cursor: pointer;
  flex: 1;
  font-weight: 600;
  transition: background-color 0.3s;
}

.user-cancel-button:hover {
  background-color: #5a6268;
}

.user-save-address-button {
  background-color: #495e95;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px;
  cursor: pointer;
  flex: 1;
  font-weight: 600;
  transition: background-color 0.3s;
}

.user-save-address-button:hover {
  background-color: #3a4d84;
}

.user-add-address-button {
  /* width: 100%; */
  margin: auto;
  max-width: none;
}

.user-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 18px;
  color: #425A8B;
}

.user-earnings-container {
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.earnings-summary {
  display: flex;
  gap: 20px;
  margin-bottom: 30px;
}

.earnings-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #e9ecef;
}

.earnings-card h4 {
  margin: 0 0 10px 0;
  color: #495057;
  font-size: 16px;
}

.earnings-value {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: #212529;
}

.sales-list {
  margin-top: 20px;
}

.sales-list h4 {
  margin-bottom: 15px;
  color: #495057;
}

.sales-table {
  overflow-x: auto;
}

.sales-table table {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px;
}

.sales-table th,
.sales-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
}

.sales-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #495057;
}

.sales-table tr:hover {
  background-color: #f8f9fa;
}

.sales-table td {
  color: #495057;
}

.coupon-card {
  background: #f0f8ff;
  border: 1px solid #b3d7ff;
}

.convert-to-coupon-btn {
  margin-top: 10px;
  padding: 8px 16px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
}

.convert-to-coupon-btn:hover {
  background: #0056b3;
}

.user-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.user-coupon-modal {
  background: white;
  padding: 24px;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
}

.coupon-form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.coupon-amount {
  margin-bottom: 15px;
}

.coupon-amount label {
  display: block;
  margin-bottom: 8px;
  color: #495057;
}

.coupon-amount input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ced4da;
  border-radius: 4px;
}

.coupon-info {
  font-size: 14px;
  color: #6c757d;
  margin: 0px;
}

.coupon-actions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.coupon-actions button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-btn {
  background: #6c757d;
  color: white;
}

.convert-btn {
  background: #28a745;
  color: white;
}

.convert-btn:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

.coupons-list,
.referral-orders-list {
  margin-top: 30px;
  margin-bottom: 30px;
}

.coupons-list h4,
.referral-orders-list h4 {
  margin-bottom: 15px;
  color: #495057;
}

.coupons-table,
.referral-orders-table {
  overflow-x: auto;
}

.coupons-table table,
.referral-orders-table table {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px;
}

.coupons-table th,
.coupons-table td,
.referral-orders-table th,
.referral-orders-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
}

.coupons-table th .referral-orders-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #495057;
}

.coupons-table tr:hover,
.referral-orders-table tr:hover {
  background-color: #f8f9fa;
}

.referral-orders-table img {
  width: 60px;
  height: 60px;
}

.table-image {
  display: flex;
  align-items: center;
  gap: 6px;
}

.coupon-status {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.coupon-status.active {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.coupon-status.used {
  background-color: #eeeeee;
  color: #616161;
}

.coupon-status.cancelled {
  background-color: #ffebee;
  color: #c62828;
}

.no-coupons {
  text-align: center;
  color: #6c757d;
  font-style: italic;
  padding: 20px !important;
}

.referral-orders-container {
  padding: 20px;
}

.referral-orders-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
}

.referral-order-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  border: 1px solid #e9ecef;
}

.referral-order-header {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.referral-code {
  font-weight: 600;
  color: #425A8B;
  font-size: 14px;
}

.referral-product-item {
  display: flex;
  gap: 15px;
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5;
}

.referral-product-item:last-child {
  border-bottom: none;
}

.referral-product-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
}

.referral-product-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.referral-product-title {
  font-weight: 500;
  margin: 0;
  color: #425A8B;
  font-size: 14px;
}

.referral-product-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.referral-product-price,
.referral-product-earning {
  font-size: 14px;
  color: #6c757d;
}

.no-referral-orders {
  text-align: center;
  padding: 20px;
  color: #6c757d;
  background: #f9f9f9;
  border-radius: 8px;
}

@media (min-width: 768px) {
  .referral-orders-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .referral-orders-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .referral-orders-container {
    padding: 10px;
  }

  .referral-order-card {
    padding: 10px;
  }

  .referral-product-image {
    width: 60px;
    height: 60px;
  }

  .referral-product-title {
    font-size: 13px;
  }

  .referral-product-price,
  .referral-product-earning {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .user-profile-container {
    padding: 0px;
  }

  .user-profile-info {
    flex-direction: column;
    text-align: center;
  }

  .user-form-row {
    flex-direction: column;
    gap: 0;
  }

  .user-form-user {
    margin-bottom: 15px;
  }

  .user-settings-navigation {
    gap: 10px;
    /* overflow-x: auto; */
    padding-bottom: 10px;
  }

  .user-settings-navigation button {
    min-width: max-content;
    font-size: 14px;
    padding: 0;
  }

  .user-address-list {
    flex-direction: column;
  }

  .user-address-card {
    padding: 10px;
    max-width: none;
    /* width: 100%; */
  }

  .user-update-button {
    max-width: none;
    width: 100%;
    margin-top: 15px;
  }

  .user-profile-content {
    padding: 10px;
  }

  .user-address-modal {
    width: 95%;
    padding: 15px;
    max-height: 80vh;
    overflow-y: auto;
  }

  .user-address-modal-form {
    overflow-y: visible;
    padding-bottom: 70px;
  }

  .user-address-modal-actions {
    flex-direction: column;
    gap: 10px;
    position: sticky;
    bottom: 0;
    background-color: white;
    padding-top: 10px;
    margin-top: 20px;
    z-index: 10;
  }

  .user-save-address-button,
  .user-cancel-button {
    width: 100%;
    padding: 10px;
  }

  .user-add-address-button {
    width: 100%;
    padding: 8px;
  }

  .user-no-address-message {
    padding: 0px;
    width: initial;
  }

  .earnings-summary {
    flex-direction: column;
  }
}

@media (max-width:450px) {
  .user-settings-navigation button {
    font-size: 12px;
  }
}

.coupons-container {
  padding: 20px;
}

.coupons-grid {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
}

.coupon-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  border: 1px solid #e9ecef;
}

.coupon-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.coupon-code {
  font-weight: 600;
  color: #425A8B;
  font-size: 16px;
  letter-spacing: 1px;
}

.coupon-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.coupon-amount,
.coupon-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.coupon-label {
  color: #6c757d;
  font-size: 14px;
}

.coupon-value {
  color: #425A8B;
  font-weight: 500;
  font-size: 14px;
}

.coupon-status {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.coupon-status.active {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.coupon-status.used {
  background-color: #eeeeee;
  color: #616161;
}

.coupon-status.cancelled {
  background-color: #ffebee;
  color: #c62828;
}

@media (min-width: 768px) {
  .coupons-grid {
    display: none;
  }

  .coupons-table {
    display: block;
  }

  .referral-orders-grid {
    display: none;
  }

  .referral-orders-table {
    display: block;
  }
}

@media (max-width: 767px) {
  .coupons-table {
    display: none;
  }

  .referral-orders-table {
    display: none;
  }
}

.coupons-table,
.referral-orders-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.coupons-table th,
.coupons-table td,
.referral-orders-table th,
.referral-orders-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #dee2e6;
}

.coupons-table th,
.referral-orders-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #495057;
}

.coupons-table tr:hover,
.referral-orders-table tr:hover {
  background-color: #f8f9fa;
}

.coupons-table td,
.referral-orders-table td {
  color: #495057;
}

.coupon-status {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.coupon-status.active {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.coupon-status.used {
  background-color: #eeeeee;
  color: #616161;
}

.coupon-status.cancelled {
  background-color: #ffebee;
  color: #c62828;
}

@media (min-width: 768px) {
  .coupons-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1024px) {
  .coupons-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .coupons-container {
    padding: 10px;
  }

  .coupon-card {
    padding: 12px;
  }

  .coupon-code {
    font-size: 14px;
  }

  .coupon-label,
  .coupon-value {
    font-size: 13px;
  }

  .coupon-status {
    font-size: 11px;
    padding: 3px 6px;
  }
}