.certificate-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  min-height: 100vh;
  gap: 40px;
  
  
}

.certificate-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 900px;
  background: #f9f9f9;
  border-radius: 16px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); 
  padding: 30px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #e0e0e0; 
}



.certificate-image {
  max-width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}



.certificate-details {
  width: 100%;
  text-align: left; 
  color: #333;
  font-size: 16px;
  line-height: 1.6;
  margin-top: 20px;
}

.certificate-title {
  font-size: 24px;
  font-weight: bold;
  color: #111; 
  margin-bottom: 16px;
  text-align: center; 
}

.certificate-text {
  font-size: 16px;
  line-height: 1.8;
  color: #444;
  background-color: #fff;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  text-align: justify; 
}


.certificate-product-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  margin-top: 20px;
}

.certificate-product-images .certificate-product-image {
  max-width: 200px;
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.no-images {
  text-align: center;
  color: #aaa;
  font-style: italic;
  font-size: 18px;
}
@media (max-width: 425px) {
  .certificate-container {
    padding: 20px; 
    min-height: auto;
  }

  .certificate-card {
    padding: 20px; 
  }
  .certificate-product-images{
    gap:5px;
  }
  .certificate-product-images .certificate-product-image{
      width:100%;
      max-width: 120px;
  }

}
