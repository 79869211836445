.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  text-align: center;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 2rem auto;
  max-width: 800px;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.not-found-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.08);
}

.not-found-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #425A8B;
}

.error-code {
  font-size: 8rem;
  font-weight: 900;
  color: #425A8B;
  margin-bottom: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.error-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #425A8B;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.error-message {
  font-size: 1.2rem;
  color: #6B83B6;
  max-width: 600px;
  margin-bottom: 2.5rem;
  line-height: 1.6;
}

.home-button {
  background-color: #425A8B;
  color: white;
  border: none;
  padding: 1rem 2.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(66, 90, 139, 0.3);
  text-decoration: none;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-button:hover {
  background-color: #344a77;
  transform: translateY(-3px);
  box-shadow: 0 6px 15px rgba(66, 90, 139, 0.4);
}

.home-button:active {
  transform: translateY(1px);
}

.error-illustration {
  max-width: 100%;
  height: auto;
  margin-bottom: 2rem;
  animation: float 3s ease-in-out infinite;
  filter: drop-shadow(0 10px 15px rgba(0, 0, 0, 0.1));
}

@keyframes float {
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
}

/* Responsive styles */
@media (max-width: 768px) {
  .error-code {
    font-size: 6rem;
  }
  
  .error-title {
    font-size: 2rem;
  }
  
  .error-message {
    font-size: 1rem;
  }
  
  .home-button {
    padding: 0.8rem 2rem;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .error-code {
    font-size: 4rem;
  }
  
  .error-title {
    font-size: 1.5rem;
  }
  
  .not-found-container {
    padding: 1.5rem;
  }
}
