.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 10px 20px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;

}

.top-bar-left {
  display: flex;
  flex: 1;

}

.top-bar-left a {
  text-decoration: none;
  cursor: pointer;
  color: #425A8B;
  font-size: 14px;
  padding-left: 25px;
}

.top-bar-center {
  display: flex;
  flex: 1;
  color: #425A8B;
  font-weight: normal;
  justify-content: center;

}

.top-bar-right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 3px;
}

.doviz {
  font-weight: bold;

}

.top-bar-right span {
  font-size: 15px;
  color: #425A8B;
  margin-right: 10px;
}

.logo-div {
  flex: 1;

}

.logo {
  width: 91.7px;
  height: 57px;
  padding-left: 60px;
}

.center {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 5px 5px;
  border-bottom: 1px solid #ddd;
  font-size: 14px;

}

.user-cart {
  display: flex;
  align-items: center;
  gap: 20px;
}

.cart {
  position: relative;
  margin-right: 50px;
}

.cart-countt {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
}

.cart-header {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.searchbarr {
  flex: 2;
  justify-content: center;

}

.searchbarr-mobile {
  display: none;
}

.cart-header-item {
  display: flex;
  align-items: center;
  position: relative;
  font-size: 12px;
  color: #3a3a3a;
}

.cart-header-item i {
  margin-right: 8px;
  font-size: 16px;
  color: #3a3a3a;
}

.cart-header-item span {
  font-size: 16px;
  color: #3a3a3a;
}

.cart-header div {
  cursor: pointer;
}

.cart span {
  font-size: 16px;
}

.cart-count {
  position: absolute;
  top: -12px;
  right: -12px;
  background-color: orange;
  color: white;
  font-size: 8px;
  border-radius: 50%;
  padding: 2px 6px;
}

.auth-title {
  font-size: 16px;

}

.header-favorite span {
  font-size: 16px;
}

.cart-dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  padding: 6px;
  min-width: 250px;
  max-height: 500px;
  overflow-y: auto;
  border-radius: 10px;
  left: -40%;
  transform: translateX(-60%);
}

.cart-dropdown::-webkit-scrollbar {
  width: 6px;
}

.cart-dropdown::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.cart-dropdown::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.cart-dropdown::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.cart-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f0f0f0;
}

.cart-item:hover {
  background-color: #f0f0f0;
  transition: background-color 0.3s ease;
}

.cart-item:last-child {
  border-bottom: none;
}

.cart-item-image {
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  display: block;
  position: relative;
}

.cart-item-image:hover {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

.cart-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  display: block;
}

.cart-item p {
  font-size: 14px;
  color: #425A8B;
}

.cart-item a {
  text-decoration: none;

}

.cart-item-details {
  padding-left: 10px;

}

.product-title {
  align-items: center;
  font-size: 12px;
  font-weight: normal;
  color: #333;
}

.cart-item-details-price {

  text-align: left;
  color: #425A8B;
  font-weight: bold;
  font-size: 15px;

}

.cart-item-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.cart-item-actions a {
  font-size: 12px;
  color: #007bff;
  text-decoration: none;

}

.cart-item-actions a:hover {
  text-decoration: underline;
}

.cart-button {
  background-color: #003366;
  color: white;
  float: right;
  height: 30px;
  width: 120px;
  margin-top: 5px;
  margin-right: 10px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}


.cart-button:hover {
  background-color: #003366;
  color: white;
}

.cart-dropdown h4 {
  margin: 0 0 10px 0;
  font-size: 18px;
  font-weight: 600;
  color: #333;
  border-bottom: 2px solid #f5f5f5;
  padding-bottom: 5px;
}

.auth-dropdown {
  cursor: pointer;
  padding: 10px;
}

.auth-dropdown a {
  text-decoration: none;
  color: black;

}

.cart-icon {
  cursor: pointer;
  padding: 2px;
}

.auth-dropdown-menu {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  min-width: 150px;
  z-index: 1000;
}

.auth-dropdown:hover .auth-dropdown-menu,
.auth-dropdown-menu:hover {
  display: block;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  color: #333;
  text-decoration: none;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

.menu {
  width: 100%;


}

@media (max-width: 425px) {
  .header {
    position: fixed;
    width: 100%;
    max-width: 100%;
    /* overflow-x: hidden; */

  }

  .top-bar {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .top-bar-left {
    width: 100%;
    justify-content: center;
    overflow-x: hidden;
    padding-left: 0;

  }

  .top-bar-right {
    display: none;
  }

  .top-bar-center {
    display: none;
  }

  .logo-div img {
    width: 80px;

  }

  .logo-div {
    width: 100%;
    justify-content: left;
  }

  .logo {
    width: 80px;
    height: 50px;
    padding-left: 10px;

  }

  .header-favorite span {
    font-size: 14px;
  }

  .cart-header .auth-dropdown span {
    display: none;
  }

  .cart-header .header-favorite span {
    display: none;
  }

  .cart-header .cart span {
    display: none;
  }

  .cart-header .cart-count {
    position: absolute;
    top: -5px;
    right: -10px;

  }

  .cart-header {
    gap: 10px;

  }

  .cart {
    margin-right: 20px;
  }

  .cart-item-details p {
    font-size: 12px;
  }

  .cart-item-image img {
    width: 40px;
    height: 40px;
  }

  .cart-button {
    width: 100px;
    font-size: 12px;
  }

  .center {
    justify-content: space-between;
    padding: 0;
  }


}

@media (max-width: 768px) and (min-width: 425px) {
  .top-bar-left {
    display: flex;
    justify-content: center;
    padding: 0;
  }

  .top-bar-center {
    display: none;
  }

  .top-bar-right {
    display: none;
  }

  .logo-div img {
    width: 80px;

  }

  .logo-div {
    width: 100%;
    justify-content: left;
  }

  .logo {
    width: 100px;
    height: 55px;
    padding-left: 10px;

  }

  .searchbarr {
    width: 100%;

  }

  .menu {
    flex-direction: column;
    align-items: center;

  }

  .center {
    justify-content: space-between;
    padding: 0;
  }

  .menu-item {
    margin: 5px 0;
    font-size: 12px;
  }

  .cart-header .cart .cart-count {
    position: absolute;
    top: -5px;
    right: -10px;
  }

  .header-favorite span {
    font-size: 14px;
  }

  .cart-header {
    gap: 5px;

  }

  .cart {
    margin-right: 20px;
  }

  .cart-header .auth-dropdown span {
    display: none;
  }

  .cart-header .header-favorite span {
    display: none;
  }

  .cart-header .cart span {
    display: none;
  }

  .cart-item-details p {
    font-size: 12px;
  }

  .cart-item-image img {
    width: 40px;
    height: 40px;
  }

  .cart-button {
    width: 100px;
    font-size: 12px;
  }

}

@media (max-width: 1440px) and (min-width: 769px) {
  .header {
    padding: 0;
  }

  .top-bar-center {
    display: none;
  }

  .top-bar-right {
    flex: 2
  }

  .top-bar-right span {
    font-size: 14px;
  }

  .cart-header {
    gap: 5px;
    flex: 2;

  }

  .center {
    padding: 5px 5px;
  }

  .user-cart {
    gap: 10px;
  }

  .cart-countt,
  .cart-count {
    font-size: 12px;
    padding: 2px 4px;
  }

  .auth-title {
    font-size: 14px;
  }

  .cart-dropdown {
    min-width: 250px;
    left: -50%;
    transform: translateX(-50%);
  }

  .cart-button {
    width: 100px;
    font-size: 12px;
  }

  .cart-item p,
  .cart-item-details p {
    font-size: 12px;
  }

  .auth-dropdown-menu {
    min-width: 130px;
  }

  .dropdown-item {
    font-size: 14px;
  }
}

@media (max-width: 1700px) and (min-width: 1441px) {
  .top-bar {

    align-items: flex-start;
    padding: 10px;
    text-align: center;
  }

  .top-bar-right {
    flex: 2;
  }

  .top-bar-center {
    flex: 2;
  }

  .top-bar-left,
  .top-bar-right,
  .top-bar-center {
    width: 100%;
  }

  .logo {
    width: 80px;
    height: 50px;
  }

  .cart-header {
    flex: 2;
    gap: 3px;
    margin: 0;
  }

  .searchbar {
    margin: 10px 0px;
  }

  .cart-item {
    padding: 3px;
  }

  .cart-item-details p {
    font-size: 14px;
  }

  .cart-button {
    width: 90px;
    font-size: 12px;
  }

}