.order-summary-second {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
}

.checkout-form {
    display: flex;
    flex-direction: column;
}

.siparis-adres-baslik h3 {
    margin: 0;
    color: #425A8B;
    font-size: 1.2rem;
}

.checkout-form-group {
    display: flex;
    /* gap: 1rem; */
}

.checkout-form-group>div {
    flex: 1;
}

.checkout-form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #425A8B;
    font-size: 0.9rem;
}

.checkout-form-group input,
.checkout-form-group select,
.checkout-form-group textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 0.9rem;
}

.checkout-form-group select {
    margin: 0;
}

.checkout-form-group textarea {
    /* min-height: 100px; */
    resize: vertical;
}

.error {
    color: #dc3545;
    font-size: 0.8rem;
    margin-top: 0.25rem;
}

.payment-method-options {
    margin-top: 1rem;
}

.payment-method-options h3 {
    margin-bottom: 1rem;
    color: #333;
    font-size: 1.1rem;
}

.payment-radio-container {
    display: flex;
    /* gap: 2rem; */
}

.payment-radio {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.checkbox-wrapper {
    display: flex;
    align-items: center;
    /* gap: 0.5rem; */
    margin: 0.5rem 0;
}

.checkbox-wrapper a {
    color: #007bff;
    text-decoration: none;
}

.checkbox-wrapper a:hover {
    text-decoration: underline;
}

.button-apply {
    background-color: #425A8B;
    color: white;
    border: none;
    padding: 1rem;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
    margin-top: 1rem;
}

.button-apply:hover {
    background-color: #304a7c;
}

.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 2rem;
    border-radius: 8px;
    max-width: 600px;
    width: 90%;
    max-height: 80vh;
    overflow-y: auto;
}

.Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #666;
}

.close-button:hover {
    color: #333;
}

@media (max-width:768px) {
    .checkout-form-group {
        flex-direction: column;
    }

    .checkout-form-group select {
        width: 100%;
    }

    .order-summary-second {
        padding: 10px;
    }

    .checkout-form-group input,
    .checkout-form-group select,
    .checkout-form-group textarea {
        width: initial;
    }

    .checkbox-wrapper {
        margin: 4px;
    }
}