.order-container{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.no-orders-message, 
.error-message {
  padding: 20px;
  text-align: center;
  border-radius: 5px;
  font-size: 16px;
  margin: 20px 0;
}

.no-orders-message {
  background-color: #f8f9fa;
  color: #6c757d;
  border: 1px solid #dee2e6;
}

.error-message {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.order-card{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.order-item{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}
.order-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    gap: 10px;
    border-bottom: 1px solid #ddd;
  }
.order-info p{
  flex: 1; 
  min-width: 150px; 
  text-align: left;
}

.order-body{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}
.product-itemm{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
}
.product-itemm img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 3px;
    margin-left: 5px;
    
  }
  .product-itemm p {
    text-align: left;
    font-weight: bold;
    color: #425A8B;
    font-size: 14px;
    padding-top: 10px;
    margin-right: 20px;
  }


.order-status {
  font-weight: bold; 
}

.order-status.failed { color: #e74c3c; /* Ödeme Başarısız */ }
.order-status.paid { color: #3498db; /* Ödeme Alındı */ }
.order-status.approved { color: #3498db; /* Onaylanan */ }
.order-status.shipped { color: #3498db; /* Kargoda */ }
.order-status.completed { color: #27ae60; /* Tamamlanan */ }
.order-status.cancelled { color: #f39c12; /* İptal Edilen */ }
.order-status.unknown { color: #7f8c8d; /* Bilinmeyen Durum */ }

.order-table-container {
  width: 100%;
  overflow-x: auto;
}

.order-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.order-table th, 
.order-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.order-table th {
  background-color: #f5f5f5;
  font-weight: bold;
  color: #425A8B;
}

.order-table img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 3px;
}

.product-row:hover {
  background-color: #f9f9f9;
}

.mobile-product-cards {
  display: none;
}

@media (max-width: 768px) {
  .order-info {
    flex-direction: column; 
  }

  .order-body {
    flex-direction: column; 
  }

  .product-itemm {
    flex-direction: column; 
    align-items: flex-start;
    gap: 0px;
  }

  .order-table-container {
    overflow-x: visible;
  }

  .order-table {
    display: none;
  }

  .order-item {
    background-color: #f5f5f5;
  }

  .mobile-product-cards {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }

  .mobile-product-card {
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    background-color: #f9f9f9;
  }

  .mobile-product-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }

  .mobile-product-header img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 4px;
    border: 1px solid #ddd;
    padding: 3px;
  }

  .mobile-product-details {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 5px 10px;
  }

  .mobile-product-label {
    font-weight: bold;
    color: #425A8B;
  }
}