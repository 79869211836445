
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: transparent 
}

.loader {
    border: 4px solid #f3f3f3; 
    border-top: 4px solid #4e5b64; 
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
@media (max-width: 768px) {
    .loader {
        width: 30px;
        height: 30px;
        border-width: 3px;
    }
}
@media (max-width: 425px) {
    .loader {
        width: 20px;
        height: 20px;
        border-width: 2px;
    }
}