.favorites {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin-left: 10px;

}

.favori-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}

.favori-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.empty-favorites {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px 20px;
  margin: 20px auto;
  max-width: 500px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.empty-favorites-icon {
  margin-bottom: 20px;
  color: #ccc;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }

  50% {
    transform: scale(1.1);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 0.7;
  }
}

.empty-favorites p {
  margin: 8px 0;
  font-size: 18px;
  color: #555;
}

.empty-favorites-message {
  color: #777;
  font-size: 16px;
  margin-bottom: 25px;
}

.shop-now-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #425A8B;
  color: white;
  padding: 12px 24px;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
  transition: all 0.3s ease;
  margin-top: 10px;
  box-shadow: 0 4px 8px rgba(66, 90, 139, 0.3);
}

.shop-now-button:hover {
  background-color: #344a77;
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(66, 90, 139, 0.4);
}

.shop-now-button:active {
  transform: translateY(-1px);
}

.favorite-icons {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  z-index: 10;
  visibility: visible !important;
  opacity: 1 !important;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  display: block !important;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  padding: 8px;
  animation: pulse 2s infinite;
}

.favori-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  text-align: left;
  transition: transform 0.3s;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 200px;
}

.favori-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.favori-img-container {
  width: 100%;
  aspect-ratio: 4/5;
  overflow: hidden;
  position: relative;
}

.favori-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.favori-card:hover img {
  transform: scale(1.05);
}

.favori-card-responsive-info {
  margin-left: 10px;
}

.favori-card-responsive-name {
  font-size: 14px;
  margin-block-start: 5px;
  margin-block-end: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

}

.favori-card-responsive-name a {
  text-decoration: none;
  color: #425A8B;
  transition: color 0.3s ease;
  font-size: 14px;


}

.favori-card-responsive-name a:hover {
  color: #425A8B;
}

.favori-pricee {
  font-size: 16px;
  font-weight: bold;
  color: #425A8B;
  margin-block-start: 5px;
  margin-block-end: 5px;
  display: flex;
  align-items: left;

}

.favori-originall-price {

  color: #333333;
  margin-right: 8px;
  font-size: 16px;
  font-weight: bold;
  margin-block-start: 0;
  margin-block-end: 0;
}

.favori-originall-price.text-decoration-line-through {
  text-decoration: line-through;
}

.favori-discountedd-price {
  color: #e63946;
  font-weight: bold;
  font-size: 16px;
  margin-block-start: 0;
  margin-block-end: 0;
}


@media (max-width: 768px) and (min-width: 426px) {
  .favorites {
    padding: 5px;
    margin-left: 0;
  }

  .favori-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    justify-items: center;
  }

  .favori-card {
    width: 100%;
    margin: 0 auto;
  }

  .favori-img-container {
    width: 100%;
    height: auto;
  }

  .favori-card-responsive-info {
    padding: 5px;
  }

  .favori-card-responsive-name {
    font-size: 16px;
    margin-block-start: 5px;
    margin-block-end: 5px;
  }

  .favori-card-responsive-name a {
    font-size: 12px;
  }

  .favori-pricee {
    font-size: 16px;
  }

  .favori-originall-price,
  .favori-discountedd-price {
    font-size: 16px;
  }

  .empty-favorites {
    padding: 30px 15px;
    max-width: 450px;
  }

  .empty-favorites p {
    font-size: 16px;
  }

  .empty-favorites-message {
    font-size: 14px;
  }
}

@media (max-width: 425px) {
  .favorites {
    padding: 5px;
    margin-left: 0;
  }

  .favori-container {
    align-items: center;
  }

  .favori-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;

  }

  .favori-card {
    width: 100%;
  }

  .favori-img-container {
    width: 100%;
    aspect-ratio: 4/5;
  }

  .favori-card-responsive-name {
    font-size: 14px;
    margin-block-start: 10px;
    margin-block-end: 0px;
  }

  .favori-originall-price {
    font-size: 16px;
  }

  .favori-originall-price.text-decoration-line-through {
    text-decoration: line-through;

  }

  .favori-discountedd-price {
    font-size: 16px;
  }

  .empty-favorites {
    padding: 25px 15px;
    margin: 10px;
    max-width: 100%;
  }

  .empty-favorites-icon {
    margin-bottom: 15px;
  }

  .empty-favorites-icon svg {
    width: 50px;
    height: 50px;
  }

  .empty-favorites p {
    font-size: 16px;
    margin: 5px 0;
  }

  .empty-favorites-message {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .shop-now-button {
    padding: 10px 20px;
    font-size: 14px;
    width: 80%;
  }
}